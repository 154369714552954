<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="10" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object">
               <template v-slot:pdf_content="{ page }">

                  <div id="title" v-if="page == 1" style="text-align:center">
                     ({{ $t('Salary Calculation') }} )
                  </div>

                  <div class="employee_number">
                     {{ $t('employee number') }} : {{ printStaticData.master.id }}
                  </div>

                  <table width="100%" id="employee_table">
                     <tr>
                        <td>{{ $t('employee name') }} </td>
                        <td colspan="3">{{ printStaticData.master.employee_name_ar || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('address') }} </td>
                        <td colspan="3">{{ printStaticData.master.address || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('date of birth') }} </td>
                        <td>{{ printStaticData.master.birthday || ' ' }} </td>
                        <td>{{ $t('email') }} </td>
                        <td>{{ printStaticData.master.email || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('nationality') }} </td>
                        <td>{{ printStaticData.master.nationality_name || ' ' }} </td>
                        <td>{{ $t('company') }} </td>
                        <td>{{ printStaticData.master.company_name || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('management') }} </td>
                        <td>{{ printStaticData.master.management_name || ' ' }} </td>
                        <td>{{ $t('department') }} </td>
                        <td>{{ printStaticData.master.department_name || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('job') }} </td>
                        <td>{{ printStaticData.master.job_name || ' ' }} </td>
                        <td>{{ $t('shift') }} </td>
                        <td>{{ printStaticData.master.shift_name || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('start contract date') }} </td>
                        <td>{{ printStaticData.contract.start_date || '' }} </td>
                        <td>{{ $t('end contract date') }} </td>
                        <td>{{ printStaticData.contract.end_date || ' ' }} </td>
                     </tr>
                     <tr>
                        <td>{{ $t('basic salary') }} </td>
                        <td>{{ printStaticData.contract.salary_fixed || 0 }} </td>
                        <td>{{ $t('allowances') }} </td>
                        <td>{{ printStaticData.contract_d.reduce((prev, current) => prev + current.value, 0) || 0 }}
                        </td>
                     </tr>
                     <tr>
                        <td>{{ $t('total advance') }} </td>
                        <td>{{ printStaticData.master.total_advance || 0 }} </td>
                        <td>{{ $t('total vacations') }} </td>
                        <td>{{ printStaticData.master.total_vacation || 0 }} </td>
                     </tr>
                  </table>

               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "EmployeePrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {

   },
   components: {
      VueHtml2pdf,
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
#employee_table {
   margin: 50px 0;
   -webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   border-collapse: collapse;
   border: 1px solid #e9e8ee;
   border-bottom: 8px solid #28aae1;
   border-right: 8px solid #28aae1;

   tr {
      border-bottom: 1px solid #000 !important;

      td {
         padding: 5px !important;
         font-weight: 600;
         border-bottom: 1px solid #ddd !important;

         &:nth-of-type(odd) {
            color: #666 !important;
            background: #e6ecff !important;
            text-align: center !important;
            border-left: 0 !important;
            width: 25%;
            padding: 5px 10px !important;
         }

         &:nth-of-type(even) {
            color: #333 !important;
            background: #f8f9fd !important;
            //border-left: 1px solid #000 !important;
            text-align: center !important;
         }

         &:last-of-type {
            border-left: 0 !important;
         }
      }

      &:last-of-type {
         border-bottom: 0 !important;

         td {
            border-bottom: 0 !important;
         }
      }

   }
}
.employee_number {
      font-size: 12px !important;
   }
</style>