<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader v-if="!employeeId" :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->

      <!-- Employee Header Screen -->
      <v-col cols="12" sm="12" v-if="employeeId">
        <div class="d-flex justify-space-between align-center">
          <span class="font-weight-bold text-h6 ma-0">{{ $t("covenant") }}</span>
          <v-btn depressed :min-width="90" @click="openForm = true" class="my-2 rounded-lg" color="primary" :height="37"
            v-if="!openForm">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("add new covenant") }}
          </v-btn>

          <div v-if="openForm">
            <v-btn depressed @click="openForm = false" :min-width="90" :loading="pageData.isLoading" text
              class="my-2 mx-2 rounded-lg white--text" :height="40" color="red">
              {{ $t('back') }}</v-btn>
            <v-btn depressed @click="save_employee" :disabled="!valid" :min-width="90" :loading="loading"
              class="my-2 mx-2 rounded-lg white--text" :height="40" color="success">
              {{ $t('create') }}</v-btn>
          </div>
        </div>
      </v-col>
      <!-- End Employee Header Screen -->

      <CovenantControl v-if="openForm" :openProfileForm="openForm" :saveEmployeeData="saveEmployeeData" />

      <v-row v-if="!openForm">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod" :printValueMethod="printMethod">
            <template v-slot:td="{ row,header }">
              <div v-if="header.key == 'name_employees'">
                <a @click="navigationToProfile(row)" class="text-decoration-underline">
                  {{ row.employee_name }}
                </a>
              </div>
            </template>

            <template v-slot:actions="{ row }">

              <!-- Profile -->
              <span>
                <v-menu offset-y open-on-hover transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="gray"
                      class="cursor_pointer mx-1">mdi-account-circle-outline</v-icon>
                  </template>
                  <v-list min-width="220">
                    <v-list-item class="flex-wrap text-center">
                      <v-list-item-title class="text-start text-capitalize">
                        <div>{{ $t("done by") }}: {{ row.username }}</div>
                        <div class="mt-3">{{ $t("job title") }}: {{ row.title_name }}</div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>

            </template>

            <template v-slot:afterTable>
              <v-row v-if="!pageData.isLoading && pageData.rows.length && employeeId" justify="space-between"
                class="font-weight-bold text-center text-body-2 mt-2">
                <v-col cols="4">{{ $t('the total number of the covenant') }}</v-col>
                <v-col cols="2">{{ covenant_length | float }}</v-col>
                <v-col cols="4">{{ $t('total amount borrowed') }}</v-col>
                <v-col cols="2">{{ total_covenant_amount | float }}</v-col>
              </v-row>
            </template>

          </DynamicTable>
        </v-col>
      </v-row>

      <v-row v-if="!pageData.isLoading && !openForm && pageData.rows.length > 0">
        <v-col cols="12" sm="12" v-if="!employeeId">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>

    <CovenantPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import CovenantControl from "./CovenantControl.vue";
import CovenantPrint from "./CovenantPrint.vue";

export default {
  name: "CovenantEntity",

  data: () => ({
    pageData: {
      screen_code: "09-018",
      url: null,
      controlRoute: "transactions/covenant_transaction-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    openForm: false,
    valid: false,
    loading: false,
    form: {},
    covenant_length: 0,
    total_covenant_amount: 0,
    printData: [],
    printStaticData: Object,
    print: false,
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    CovenantControl,
    CovenantPrint
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.employeeId ? this.getEmployeeData() : this.getData();
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.employeeId ? this.getEmployeeData() : this.getData();
    },
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      !this.employeeId ? this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "date", key: "add_date", type: 'text', classes: "" },
        { text: "name of the covenant", key: "covenant_name", type: 'text', classes: "" },
        { text: "employee name", key: "name_employees", type: 'slot', classes: "" },
        { text: "created by", key: "username", type: 'text', classes: "" },
        { text: "value of the covenant", key: "covenant_amount", type: 'float', classes: "" },
        { text: "actions", key: "td", type: 'actions', classes: "" },
      ] :
        this.pageData.tableHeader = [
          { text: "serial", key: "id", type: 'text', classes: "" },
          { text: "date of adding the covenant", key: "add_date", type: 'text', classes: "" },
          { text: "the covenant name", key: "covenant_name", type: 'text', classes: "" },
          { text: "covenant value", key: "covenant_amount", type: 'float', classes: "" },
          { text: "notes", key: "notes", type: 'text', classes: "" },
        ]
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`covenant${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getEmployeeData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`get_employee_covenant/${this.employeeId}${this.pageData.queryParam}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.rows = response.data;
            this.covenant_length = response.data.length;
            this.total_covenant_amount = response.data.reduce((prev, current) => prev + +current.covenant_amount, 0);
          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      this.$api.POST_METHOD(`covenant/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },

    restoreMethod(row) {
      this.$api.POST_METHOD(`covenant_action_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/covenant/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    saveEmployeeData(form, valid) {
      this.form = form
      this.valid = valid
    },
    save_employee() {
      if (this.valid) {
        this.loading = true;
        this.form.employee_id = +this.employeeId
        this.$api.POST_METHOD(`covenant`, this.form).then((response) => {
          this.loading = false;
          if (response.check) {
            this.openForm = false
            this.getEmployeeData()
          }
        })
      }
    },
    navigationToProfile(row) {
      this.$router.push(`/codes/employee-control/${row.employee_id}`)
    },
    printMethod(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api
        .GET_METHOD(`covenant/${row.id}`).then((response) => {
          this.pageData.printLoading.loading = false;
          if (response.check) {
            this.printData = [response.data.data];
            this.printStaticData.master = response.data.data;
            this.print = true;
            setTimeout(() => {
              this.printData = [];
              this.printStaticData = Object;
              this.print = false;
              this.pageData.printLoading.loading = false;
            }, 1000);
          }
        });
    },
  },
  props: {
    employeeId: { type: String }
  }
};
</script>

<style lang="scss" scoped>
.list_container,
.v-list-item {
  max-height: 103px !important;
  min-height: auto !important;
}
</style>