import summery from '@/plugins/summery.js';
export const EntityMixins = {
  data: () => ({
    endpoint: null,
    showEndpoint: null,
    extraHeaderParams: '',
    tab_type: 1, // 1 => Master & Details, 2 => Iframe
    screen_type: 1, // 1 => Code, 2 => Transaction, 3 => Invoice, 4 => Report
    response: {},
    pageData: {
      entityMixin: true,
      screen_code: null,
      list_key: 'items',
      response: {},
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
      from_date: null,
      to_date: null,
      to: null,
      from: null,
      date: null,
    },
  }),
  watch: {
    $route() {
      this.pageData.queryParam = new URLSearchParams();
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
  },
  methods: {
    queryParams() {
      const { activeScreen } = this.$store.state;
      const { queryParams, requestParam } = activeScreen;
      this.pageData.isTrashed = Number(queryParams?.active) || 0;
      this.pageData.page = Number(queryParams?.page) || 1;
      this.pageData.rowsNumber = Number(queryParams?.rows) || 15;
      this.pageData.search = queryParams?.word || '';
      this.pageData.queryParam = new URLSearchParams(requestParam || '');
    },
    pageMainData() {
      const { activeScreen } = this.$store.getters;
      this.pageData.screen_code = activeScreen.screen_code || null;
      this.pageData.main = activeScreen.main_title || null;
      this.pageData.category = activeScreen.cat_title || null;
      this.pageData.entityName = activeScreen.sub_title || null;
      this.pageData.url = activeScreen.url || null;
      const ROUTE = this.pageData.url.startsWith("/") ? "" : "/";
      this.pageData.controlRoute = `${ROUTE}${this.pageData.url}-control/`;
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`${this.endpoint}?${this.pageData.queryParam.toString()}&${this.extraHeaderParams}`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.response = response.data;
              const listKeyData = response.data[this.pageData.list_key] || {};
              const {
                data: rows = [],
                current_page: page = 0,
                last_page: totalPages = 0,
                per_page: perPage = 0,
                total: totalRows = 0
              } = listKeyData;
              this.pageData.entityName = this.pageData.isTrashed === 0 ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = rows;
              this.pageData.page = page;
              this.pageData.pagination = { page, totalPages, per_page: perPage, totalRows, };
              this.setResponse(response);
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    setResponse() { },
    getSearch(word) {
      this.pageData.queryParam.set("word", word || '')
      this.pageData.queryParam.set("page", 1)
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, ''))
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("page", page || 1);
      this.pageData.queryParam.set("rows", limit || 15);
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, ''))
    },
    edit(row) {
      const CONTAINS_HASH = this.pageData.controlRoute.startsWith("/")
      this.$router.push(`${CONTAINS_HASH ? '' : '/'}${this.pageData.controlRoute}${row.id || row.m_id}`)
    },
    toggleActiveMethod(ID) {
      console.log(ID);
      if (ID) {
        this.$api.POST_METHOD(`${this.endpoint}_toggle_active/${typeof ID === 'object' ? ID.id || ID.m_id : ID}`, null).then((response) => {
          if (response.check) {
            this.getData();
          }
        });
      }
    },
    openInNewTab(row) {
      let summeryArray = summery[this.$store.getters.activeScreen.screen_code]
      let title = ''
      switch (this.screen_type) {
        case 1: title = this.$store.getters.activeScreen.sub_title; break;
        case 2: title = `${this.$store.getters.activeScreen.sub_title} -${this.$t('action number')} ${row.m_id || row.id}`; break;
        case 3: title = `${this.$store.getters.activeScreen.sub_title} -${this.$t('invoice number')} ${row.m_id || row.id}`; break;
        default: title = this.$store.getters.activeScreen.sub_title; break;
      }
      this.$store.commit('Settings/ADD_TAB',
        {
          screen_type: this.screen_type,
          screen_code: this.$store.getters.activeScreen.screen_code,
          screen_url: this.pageData.url,
          tab_type: this.tab_type,
          title: title,
          endpoint: (this.showEndpoint || this.endpoint) + '/' + (row.id || row.m_id),
          type: 'form',
          request_done: false,
          request_load: false,
          table_header: [],
          details: [],
          master: [...summeryArray]
        },
      );
    }
  },
};
