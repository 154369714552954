<template>
  <section id="careers_jobs">
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" createBtnText="add a job"
        :showDeleted="false" />
      <!-- End Of Header -->

      <!-- Tabs -->
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab class="transparent">{{ $t('current') }}</v-tab>
        <v-tab class="transparent">{{ $t('The ending') }}</v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <!-- Cards -->
      <v-expand-transition>
        <v-row class="mt-2" v-if="tab === 0 && !pageData.isLoading && pageData.rows?.length">
          <v-col cols="12" md="4">
            <v-card class="pa-3 font-weight-bold" elevation="0">
              <div>{{ $t('Number of current jobs') }}</div>
              <div class="mt-3 text-h4 font-weight-bold">{{ careers?.total_careers || 0 }}</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="number_applicants pa-3 font-weight-bold" elevation="0">
              <div>{{ $t('Number of applicants so far') }}</div>
              <div class="mt-3 text-h4 font-weight-bold">{{ careers?.total_applicants || 0 }}</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="pa-3 font-weight-bold" elevation="0">
              <div>{{ $t('Disabled jobs') }}</div>
              <div class="mt-3 text-h4 font-weight-bold">{{ careers?.disabled_careers || 0 }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Table -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :restoreValueMethod="actionMethod">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'applicants_count'">
                <div v-if="row?.status == 2">
                  <v-badge color="gray2 yellow2--text" :content="$t('off')" inline>
                    <div> {{ row.applicants_count }}</div>
                  </v-badge>
                </div>
                <div v-else>
                  <div> {{ row.applicants_count }}</div>
                </div>
              </div>
            </template>

            <template v-slot:actions="{ row }">

              <!-- return canceled -->
              <v-scroll-x-reverse-transition>
                <div class="mx-3" v-if="tab === 1">
                  <v-btn @click="openRestartDialog(row)" class="blue6 white--text" elevation="0" height="30">
                    <!-- text -->
                    <span class="mx-1"> {{ $t('Restart') }}</span>

                    <!-- icon -->
                    <v-icon color="white">mdi mdi-arrow-u-left-top</v-icon>
                  </v-btn>
                </div>
              </v-scroll-x-reverse-transition>

              <!-- show -->
              <span>
                <v-tooltip bottom color="blue6">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="edit(row)" class="blue12" v-bind="attrs" v-on="on" icon elevation="0">
                      <v-icon color="blue3">mdi mdi-eye-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-caption">{{ $t('details') }}</span>
                </v-tooltip>
              </span>

              <!-- play and suspend -->
              <v-scroll-x-transition>
                <div class="mx-3" v-if="tab === 0">
                  <v-btn @click="dialogActions(row, 'suspense')" width="90"
                    :class="row.status == 1 ? 'yellow2 gray5--text' : 'blue6 white--text'" class="font-weight-bold"
                    elevation="0" height="30">
                    <!-- text -->
                    <span class="mx-1"> {{ row.status == 1 ? $t('suspense') : $t('working') }}</span>

                    <!-- icon -->
                    <v-icon :color="row.status == 1 ? 'gray5' : 'white'">
                      {{ row.status == 1 ? 'mdi mdi-pause-circle-outline' : 'mdi mdi-play-circle-outline' }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-scroll-x-transition>


              <!-- stop-->
              <v-scroll-x-transition>
                <div v-if="tab === 0">
                  <v-btn @click="dialogActions(row, 'ending')" width="90" color="gray11"
                    class="font-weight-bold red--text" elevation="0" height="30">
                    <!-- text -->
                    <span class="mx-1"> {{ $t('ending') }}</span>

                    <!-- icon -->
                    <v-icon color="red">mdi mdi-close-circle-outline</v-icon>
                  </v-btn>
                </div>
              </v-scroll-x-transition>


            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <!-- Pagination -->
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading" />
        </v-col>
      </v-row>
      <!-- End of Table -->
    </v-container>

    <ActionDialog :openDialog="actionDialog" :closeDialog="() => actionDialog = false" :rowData="rowData"
      :confirmAction="confirmAction" />
    <RestartDialog :openDialog="restartDialog" :closeDialog="() => restartDialog = false" :rowData="rowData"
      :getData="getData" />
  </section>
</template>

<script>
import ActionDialog from './ActionDialog.vue';
import RestartDialog from './RestartDialog.vue';
export default {
  name: "CareersJobsEntity",

  data: () => ({
    pageData: {
      screen_code: "10-001",
      url: null,
      controlRoute: "careers/jobs-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    tab: 0,
    status: '',
    actionStatus: '',
    actionDialog: false,
    restartDialog: false,
    rowData: {},
    careers: {}
  }),
  components: {
    ActionDialog,
    RestartDialog
  },
  computed: {
    newEmployeesCount() {
      return (count) => `${this.$i18n.t('new')} +${+count}`
    }
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      if (this.tab == 0) {
        this.status = '';
      } else {
        this.status = 3;
      }
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "Advertisement date", key: "add_date", type: 'text', classes: "" },
        { text: "Expiry date", key: "to_date", type: 'text', classes: "" },
        { text: "Job name", key: "job_name", type: 'text', classes: "" },
        { text: "Number of applicants", key: "applicants_count", type: 'slot', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: false,
        edit: false,
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`career${this.pageData.queryParam}&status=${this.status || ""}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.careers.total_careers = response.data.total_careers
              this.careers.total_applicants = response.data.total_applicants
              this.careers.disabled_careers = response.data.disabled_careers
              console.log('careers', this.careers);
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    confirmAction(row) {
      if (row.action_name === 'suspense') {
        this.actionStatus = 2;
      } else if (row.action_name === 'ending') {
        this.actionStatus = 3;
      }
      this.actionMethod(row)
    },
    openRestartDialog(row) {
      this.rowData = row
      this.restartDialog = true;
    },
    dialogActions(row, action_name) {
      if (row.status == 2 && action_name !== 'ending') {
        this.actionStatus = 1;
        this.actionMethod(row)
      } else {
        row.action_name = action_name
        this.rowData = row
        this.actionDialog = true;
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      console.log(this.pageData.queryParam);
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    actionMethod(row) {
      this.$api.POST_METHOD(`career_toggle_status/${row.id}`, { status: this.actionStatus }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>


<style lang="scss" >
#careers_jobs {

  .number_applicants {
    box-shadow: 0px -2px 1px 0px var(--green2) !important;

    .v-badge__badge {
      border-radius: 8px !important;
      padding: 11px 8px !important;
      height: unset;
    }
  }

}
</style>
