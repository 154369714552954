<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" v-if="!tab" />
      <!-- End Of Header -->

      <!-- Employee Header -->
      <v-col cols="12" sm="12" v-if="tab">
        <div class="d-flex justify-space-between align-center">
          <span class="font-weight-bold text-h6 ma-0">{{ $t("salary increases") }}</span>
          <v-btn depressed :min-width="90" @click="openForm = true" class="my-2 rounded-lg" color="primary" :height="37"
            v-if="!openForm">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("add a new increase") }}
          </v-btn>

          <div v-if="openForm">
            <v-btn depressed @click="openForm = false" :min-width="90" :loading="pageData.isLoading" text
              class="my-2 mx-2 rounded-lg white--text" :height="40" color="red">
              {{ $t('back') }}</v-btn>
            <v-btn depressed @click="save_employee" :disabled="!valid" :min-width="90" :loading="loading"
              class="my-2 mx-2 rounded-lg white--text" :height="40" color="success">
              {{ $t('create') }}</v-btn>
          </div>
        </div>
      </v-col>

      <SalaryIncreaseControl v-if="openForm" :openProfileForm="openForm" :saveEmployeeData="saveEmployeeData" />

      <!-- End Employee Header -->
      <v-row v-if="!openForm">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod" :printValueMethod="printMethod">
            <template v-slot:th="{ header }">
              {{ $t(header.text) }}
            </template>
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'employee_name'">
                <a @click="navigationToProfile(row)" class="text-decoration-underline">
                  {{ row.employee_name }}
                </a>
              </div>
              <div v-if="header.key == 'salary_after_increase'">
                <div>
                  {{ row.previous_salary + row.amount | float }}

                 </div>
              </div>
            </template>




            <template v-slot:afterTable>
              <v-row v-if="!pageData.isLoading && pageData.rows.length && tab" justify="space-around"
                class="font-weight-bold text-center text-body-2 mt-1">
                <v-col cols="3" class="pa-1">{{ $t('total number of increases') }}</v-col>
                <v-col cols="auto" class="pa-1">{{ SalaryData_length | float }}</v-col>
                <v-col cols="3" class="pa-1">{{ $t('current salary') }}</v-col>
                <v-col cols="auto" class="pa-1">{{ total_Salary_amount | float }}</v-col>
              </v-row>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && !tab && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
    <SalaryIncreasePrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import SalaryIncreaseControl from "./SalaryIncreaseControl.vue";
import SalaryIncreasePrint from "./SalaryIncreasePrint.vue";
export default {
  name: "SalaryIncreaseEntity",

  data: () => ({
    pageData: {
      screen_code: "09-012",
      url: null,
      controlRoute: "transactions/salary_increase-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    printData: [],
    printStaticData: Object,
    print: false,
    openForm: false,
    saveData: false,
    valid: false,
    loading: false,
    form: {},
    SalaryData_length: 0,
    increase_value: 0,
    increase_type: null,
    increase_type_tree: [],
    increases: [],
    total_Salary_amount: 0,
   }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    SalaryIncreaseControl,
    SalaryIncreasePrint
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.tab ? this.getEmployeeData() : this.getData();
    this.increase_type_tree = [
      {
        id: 1,
        name: this.$i18n.t('Value')
      },
      {
        id: 2,
        name: this.$i18n.t('ratio')
      },
    ]
  },
  props: {
    tab: { type: Number }
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.tab ? this.getEmployeeData() : this.getData();
    },
  },

  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      !this.tab ? this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "date", key: "add_date", type: 'text', classes: "" },
        { text: "employee name", key: "employee_name", type: 'slot', classes: "" },
        { text: "increase type", key: "increase_type", type: 'text', classes: "" },
        { text: "previous salary", key: "previous_salary", type: 'float', classes: "" },
        { text: "the increase", key: "amount", type: 'float', classes: "" },
        { text: "salary after increase", key: "salary_after_increase", type: 'slot', classes: "" },
        { text: "created by", key: "added_by", type: 'text', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ] :
        this.pageData.tableHeader = [
          { text: "increase number", key: "id", type: 'text', classes: "" },
          { text: "increase date", key: "add_date", type: 'text', classes: "" },
          { text: "increase type", key: "increase_type", type: 'text', classes: "" },
          { text: "previous salary", key: "previous_salary", type: 'float', classes: "" },
          { text: "the amount", key: "amount", type: 'float', classes: "" },
          { text: "salary after increase", key: "salary_after_increase", type: 'slot', classes: "" },
        ]

      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`${'salary_increase'}${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.increases = response.data.increases
              console.log('increases',this.increases);

              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;

              response.data.items.data.forEach((row) => {
                switch (row.increase_type) {
                  case 1:
                    row.increase_type = this.$i18n.t('value')
                    break;
                  case 2:
                    row.increase_type = this.$i18n.t('ratio')
                    break;
                }
              });

              response.data.items.data.forEach((row) => {
                row.salary_after_increase = row.amount + row.salary_total
              });



              this.pageData.rows = response.data.items.data;


              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getEmployeeData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`get_increase_salary_employee/${this.$route.params.id}${this.pageData.queryParam}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check && response.data.length > 0) {
            console.log("get_increase_salary_employee", response);
            this.pageData.rows = response.data;
            this.SalaryData_length = response.data.length;
            this.total_Salary_amount = response.data[0].salary_after_increase
             response.data.forEach((row) => {
              switch (row.increase_type) {
                case 1:
                  row.increase_type = this.$i18n.t('value')
                  break;
                case 2:
                  row.increase_type = this.$i18n.t('ratio')
                  break;
              }
            });

          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`salary_increase/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`salary_increase_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/purchases_vendor/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    saveEmployeeData(form, valid, amount, salary_after_increase, employee_id ,previous_salary) {
      console.log("saveEmployeeData SalaryIncrease form", form);
      this.valid = valid
      this.form = form
      this.form.employee_id = +employee_id
      this.form.amount = amount
      this.form.salary_after_increase = salary_after_increase
      this.form.previous_salary = +previous_salary
    },
    save_employee() {
      console.log("save_employee ");
      if (this.valid) {
        this.loading = true;
        // this.form.employee_id = +this.employeeId
        this.$api.POST_METHOD(`salary_increase`, this.form).then((response) => {
          this.loading = false;
          if (response.check) {
            this.openForm = false
            this.getEmployeeData()
          }
        })


      }
    },
    navigationToProfile(row) {
      this.$router.push(`/codes/employee-control/${row.employee_id}`)
    },
    ConvertToPercentage(PartialValue, TotalValue) {
      return ((100 * PartialValue) / TotalValue).toFixed(0);
    },
    printMethod(row) {
      console.log('row', row);
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api
        .GET_METHOD(`salary_increase/${row.id}`).then((response) => {
          this.pageData.printLoading.loading = false;
          if (response.check) {

            // convert Percentage to value
            if (response.data.data.increase_type == 2) {
              this.increase_value = this.ConvertToPercentage(response.data.data.amount, response.data.data.salary_total)
            } else {
              this.increase_value = response.data.data.amount
            }
            // convert increase type from number to text
            this.increase_type = this.$global.FilterArrayOfObjectElement(this.increase_type_tree, "id", response.data.data.increase_type, "name")

            this.printData = [response.data.data];
            this.printStaticData.master = response.data.data;
            this.printStaticData.master.increase_value = this.increase_value
            this.printStaticData.master.increase_type = this.increase_type
            this.print = true;
            setTimeout(() => {
              this.printData = [];
              this.printStaticData = Object;
              this.print = false;
              this.pageData.printLoading.loading = false;
            }, 1000);
          }
        });
    },
  },




};
</script>
