<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <ControlHeader v-if="!$route.params.id" :pageData="pageData" :form="form" :save="save" :valid="valid"
        :deleteMethod="deleteMethod" :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
        :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
        :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true"></ControlHeader>
      <!-- End Of Header -->

      <!-- Edit -->
      <CareersJobCategory v-if="$route.params.id" />
      <!-- Edit -->

      <!-- Form -->
      <v-form ref="form" v-model="valid" v-if="!$route.params.id" class="mt-5">
        <v-card class="overflow-hidden py-5 px-1 transparent" elevation="0">
          <v-row>
            <v-col cols="12" class="font-weight-bold">
              <label>{{ $t('Job data') }}</label>
            </v-col>

            <GenericInput type="select" :value="form.job_id" @input="form.job_id = $event" label="Choose the job"
              :lookups="jobs" selected_label="name" :required="false" :multi="false" selected_prop="id" :isLoading="false"
              :cols="[12, 6, 6]" />

            <GenericInput type="number" :value="Math.abs(form.applicants_needed)" @input="form.applicants_needed = $event"
              label="Number of applicants" placeholder="The number of applicants you want" :isLoading="false"
              :clearable="true" :required="true" :cols="[12, 6, 6]"></GenericInput>

            <GenericInput type="date" :value="form.from_date" @input="form.from_date = $event" label="from date"
              :required="true" :isLoading="false" :clearable="true" :cols="[12, 6, 6]"></GenericInput>

            <GenericInput type="date" :value="form.to_date" @input="form.to_date = $event" label="to date"
              :minDate="form.from_date" :required="true" :isLoading="false" :clearable="true" :cols="[12, 6, 6]">
            </GenericInput>

            <!-- <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="Job title in Arabic"
              :required="true" :isLoading="false" :cols="[12, 4, 4]" />

            <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="Job Title in English"
              :required="true" :isLoading="false" :cols="[12, 4, 4]" />

            <v-col cols="12" class="font-weight-bold">
              <label>{{ $t('Job details') }}</label>
            </v-col>
            <GenericInput type="texteditor" :value="form.description" @input="form.description = $event" label="details"
              :rowsNumber="6" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput> -->
          </v-row>
        </v-card>
      </v-form>
      <!-- End of Form -->
    </v-container>
  </section>
</template>



<script>
import CareersJobCategory from "./CareersJobCategory.vue";
export default {
  components: { CareersJobCategory },
  name: "CareersJobsControl",

  data: () => ({
    pageData: {
      screen_code: "10-001",
      url: null,
      controlRoute: "/careers/jobs-control/",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isEdit: false,
      editIsLoading: false,
    },
    viewForm: false,
    valid: false,
    jobs: [],
    form: {
      from_date: null,
      to_date: null,
      applicants_needed: null,
      job_id: null,
      // name_ar: null,
      // name_en: null,
      // description: null,
    },
  }),
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData()
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        this.$api.POST_METHOD(`career`, this.form).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.$router.push(this.pageData.url);
          }
        });
      }
    },
    getData() {
      if (!this.$route.params.id) {
        this.$api.GET_METHOD(`career/create`).then((response) => {
          if (response.check) {
            this.jobs = response.data.jobs;
          }
        })
      }
    },
  },

};
</script>
