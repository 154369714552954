<template>
    <v-dialog v-model="openDialog" persistent>
        <v-card>
            <v-card-actions>

                <!-- Title -->
                <v-card-text class="font-weight-bold text-h6 text-capitalize">
                    {{ $t("employees") }}
                </v-card-text>

                <!-- Search -->
                <v-col cols="12" lg="auto" sm="auto" class="py-0">
                    <v-text-field @keydown.enter="(e) => getSearch(e.target.value)"
                        @click:clear="(e) => getSearch(e.target.value)" class="rounded-pill" background-color="#eceef0"
                        @click:append="(e) => getSearch(e.target.value)" append-icon="mdi-magnify"
                        :placeholder="$i18n.t('Search')" single-line clearable hide-details solo dense
                        :height="37"></v-text-field>
                </v-col>

                <!-- Search -->
                <!-- <v-col cols="12" lg="auto" sm="auto" class="py-0">
                    <GenericInput type="text" :value="name_ar" @input="name_ar = $event" placeholder="Search"
                        :required="false" classes="search" :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 'auto']">
                        <template v-slot:append>
                            <v-icon class="cursor_pointer">mdi-magnify</v-icon>
                        </template>
                    </GenericInput>
                </v-col> -->

                <!-- Close -->
                <v-btn small fab color="red5" text @click="closeDialog">
                    <v-icon color="red5">mdi-close</v-icon>
                </v-btn>

            </v-card-actions>

            <v-card-text>

                <!-- Table -->
                <v-row>
                    <v-col cols="12" sm="12">
                        <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="tableHeader"
                            :option="pageData.options" :pageData="pageData" />
                    </v-col>
                </v-row>


                <!-- Pagination -->
                <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
                    <v-col cols="12" sm="12">
                        <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber"
                            :backValueMethod="changePage" :isLoading="pageData.isLoading"></Pagination>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { EntityMixins } from "@/mixins/EntityMixins";
export default {
    name: "CompanyEmployeesDialog",
    mixins: [EntityMixins],
    data: () => ({ name_ar: null }),
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        row_id: { default: Number },
    },
    computed: {
        tableHeader() {
            return [
                { text: "code", key: "id", type: 'text', classes: "" },
                { text: "employee name", key: "employee_name", type: 'text', classes: "" },
                { text: "administration", key: "management_name", type: 'text', classes: "" },
                { text: "nationality", key: "nationality_name", type: 'text', classes: "" },
                { text: "position", key: "job_name", type: 'text', classes: "" },
            ]
        }
    },
    mounted() {
        this.endpoint = `get_company_employees/${this.row_id}`;
        this.pageData.list_key = `item`;
        this.getData();
    },
    watch: {
        openDialog() {
            if (this.openDialog) {
                this.getData();
            }
        }
    },
    methods: {
        setResponse(response) {
            console.log('get_company_employees', response);
            // this.pageData.rows = response.data.item.data  
        },
        getSearch(searchWord) {
            this.pageData.queryParam.set("word", searchWord || '')
            this.getData()
        },
    },

}
</script>

<style lang="scss" scoped>
.v-card__actions {
    position: sticky;
    top: 0;
    z-index: 10000;
    background: white;
    height: 68px;
}

.search {
    
    .v-input__slot {
        background: #C2CFE0 !important;
        border-radius: 15px !important;
    }
}
</style>
