<template>
  <v-menu v-model="datePicker" :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name" 
    @keydown.enter="keydownEnter()" :class="classes" :close-on-content-click="false"
    :label="inputDesign == true ? '' : $i18n.t(label)" :nudge-right="40" transition="scale-transition" offset-y
    min-width="auto" :error-messages="$i18n.t(errorMessage)" :persistent-hint="hint ? true : false" :hint="$i18n.t(hint)">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="input" append-icon="mdi-calendar" @click:append="datePicker = true" :solo="solo" outlined
        :reverse="reverse" :dense="dense" :name="name" @keydown.enter="keydownEnter()" :placeholder="$i18n.t(placeholder)" :color="color" :background-color="background"  :class="classes"
        readonly v-bind="attrs" v-on="on" :disabled="disabled" :clearable="clearable" :rules="
          required == true
            ? $global.requiredRule
            : $global.notRequiredRule
        " :loading="isLoading" rounded-md :hide-details="hideDetails == true ? 'auto' : false">
        <template v-slot:label>
          <label v-if="inputDesign == 'default'">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </v-text-field>
    </template>
    <v-date-picker color="primary" :min="minDate ? minDate : ''" :max="maxDate ? maxDate : ''" :type="datePickerType"
      :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" v-model="input" @input="datePicker = false"></v-date-picker>
  </v-menu>
</template>


<script>
export default {
  name: "DateInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    background: { default: null},
    icon: { default: "" },
    hideDetails: { default: false },
    inputDesign: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    isPickerOpened: { default: Function },
    minDate: { default: null },
    maxDate: { default: null },
    errorMessage: { default: null },
    hint: { default: null },
    datePickerType: { default: 'date' },
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    datePicker: false,
    timePicker: false,
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    datePicker() {
      this.isPickerOpened(this.datePicker)
    },
    timePicker() {
      this.isPickerOpened(this.timePicker)
    },
  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">

</style>
