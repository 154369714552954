<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.user_full_name" @input="form.user_full_name = $event"
                     label="fullname" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                  </GenericInput>
                  <GenericInput type="mobile" :value="form.mobile" @input="form.mobile = $event" label="mobileNumber"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]"></GenericInput>
                  <GenericInput type="email" :value="form.email" @input="form.email = $event" label="Email"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]"></GenericInput>
                  <GenericInput type="text" :value="form.username" @input="form.username = $event" label="username"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]"></GenericInput>

                  <GenericInput type="password" :value="form.password" @input="form.password = $event" label="password"
                     :required="pageData.isEdit ? false : true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                  </GenericInput>
                  <v-col cols="12" class="pa-0">
                     <v-divider class="mx-3 mb-4"></v-divider>
                  </v-col>
                  <GenericInput type="select" :lookups="titles" :value="form.title_id" @input="form.title_id = $event"
                     label="job" selected_label="name" selected_prop="id" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]"></GenericInput>

                  <GenericInput type="select" :lookups="titles" :value="form.user_titles"
                     @input="form.user_titles = $event" label="responsibilities" selected_label="name"
                     selected_prop="id" :multi="true" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 4, 4]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="companies" :value="form.default_company"
                     @input="form.default_company = $event" label="the companies" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="years" :value="form.default_year"
                     @input="form.default_year = $event" label="Fiscal year" selected_label="name" selected_prop="id"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                  </GenericInput>
                  <!-- <GenericInput type="select" :lookups="sales_agents" :value="form.sales_agent_id"
                     @input="form.sales_agent_id = $event" label="sales agent" selected_label="name_ar"
                     selected_prop="sales_agent_id" :multi="false" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 4, 4]"></GenericInput> -->

                  <GenericInput type="checkbox" :value="form.user_active" @input="form.user_active = $event ? 1 : 0"
                     label="active" v-if="pageData.isEdit" :cols="[12, 4, 4]"></GenericInput>
                  <GenericInput type="dropzone" :value="form.image_url" @input="form.image_url = $event"
                     :cols="[12, 12, 12]" label="user image" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading"></GenericInput>
                  <!-- <v-col cols="12" sm="6">
                     <img
                        v-if="form.image_url"
                        class="ma-auto d-block"
                        :src="$api.serverUrl + form.image_url"
                        :alt="form.username"
                     />
                  </v-col>-->
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "usersControl",
   data: () => ({
      pageData: {
         screen_code: "01-008",
         url: null,
         controlRoute: "main/user-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      sales_agents: [],
      companies: [],
      titles: [],
      years: [],
      form: {
         user_full_name: null,
         username: null,
         mobile: null,
         email: null,
         password: null,
         image_url: null,
         default_company: null,
         default_year: null,
         sales_agent_id: null,
         title_id: null,
         user_titles: [],
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('user') : this.$i18n.t('add new user');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`user/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.form.user_titles = response.data.data.user_titles.split(',').map(i => Number(i));
                     this.titles = response.data.titles;
                     this.sales_agents = response.data.sales_agents;
                     this.companies = response.data.companies;
                     this.years = response.data.financial_years;
                     // Object.entries(response.data.all_my_branchs).map(key => this.companies.push({
                     //    id: Number(key[0]),
                     //    name: key[1],
                     // }))
                     // Object.entries(response.data.all_my_years).map(key => this.years.push({
                     //    id: Number(key[0]),
                     //    name: key[1],
                     // }))
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`user/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.titles = response.data.titles;
                     this.sales_agents = response.data.sales_agents;
                     this.companies = response.data.branchs;
                     this.years = response.data.financial_years;
                     // this.form.user_titles = response.data.user_titles.split(',').map(i => Number(i));
                     // Object.entries(response.data.all_my_branchs).map(key => this.companies.push({
                     //    id: Number(key[0]),
                     //    name: key[1],
                     // }))
                     // Object.entries(response.data.all_my_years).map(key => this.years.push({
                     //    id: Number(key[0]),
                     //    name: key[1],
                     // }))
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`user/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`user`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`user/${this.form.user_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
