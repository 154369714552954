<template>
   <section>
      <v-container fluid class="pa-5">
         <v-row align="center">
            <v-col cols="6" class="text-h6 font-weight-bold">{{ $t('my personal account') }}</v-col>
         </v-row>

         <!-- Form -->
         <v-form ref="form" v-model="valid">
            <v-card class="transparent py-5 px-1" elevation="0">
               <v-row class="text-capitalize" justify="space-between">

                  <!-- Avatar -->
                  <v-col cols="12" md="2" lg="2" class="d-flex align-center justify-center">
                     <v-avatar size="130" class="employeeAvatar rounded-circle ma-5">
                        <LazyImage :src="$api.serverUrl + $store.state.userData.image"
                           :error="require('@/assets/img/svg/default_profile.svg')"
                           :loading="require('@/assets/img/png/files/loading.gif')" />
                     </v-avatar>
                  </v-col>

                  <!-- Profile Details -->
                  <v-col cols="12" md="7" lg="7">
                     <v-card-text>
                        <div class="text-h8 "> <span>{{ '185' }}</span> {{ 'مدير الخزنة' }}</div>
                        <div class="text-h6 font-weight-bold mt-1">
                           {{ 'zayd mohamed fayed - زياد محمد فايد' }}
                        </div>
                        <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('company') }}</span> :
                           <span>{{ 'أسرار الساحبة' }}</span>
                        </div>
                        <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('the management') }}</span> :
                           <span>{{ 'البرامجة' }}</span>
                        </div>
                        <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('the department') }}</span> :
                           <span>{{ 'برمجه Front - End' }}</span>
                        </div>
                        <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('fingerprint code') }}</span> :
                           {{ '55245245232' }}
                        </div>
                        <div class="text-h8 mt-1"> <span class="font-weight-bold">{{ $t('working since') }}</span> :
                           {{ 'يناير -2022 ( 3 سنوات - 5 شهور )' }}
                        </div>
                     </v-card-text>
                  </v-col>

                  
                  <v-col cols="12" md="auto" lg="auto" class="mt-9 text-end">
                     <v-btn height="34" min-width="97" @click="printPdf" :to="pageData.controlRoute"  color="blue11" class="primary--text"
                        :loading="pageData.isLoadingPdf" elevation="0">
                        {{ $t("edit") }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import LazyImage from '@/components/ui/LazyImage.vue';


export default {
   name: "PersonalAccountEntity",

   data: () => ({
      pageData: {
         screen_code: "01-008",
         url: null,
         controlRoute: "/settings_hr/personal_account-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      employees: [],
      vacations: [],
      employee_data: [],
      calendar: [],
      openDialog: false,
      attachmentDialog: false,
      vacation_summary_name: "",
      employee_profile_id: "",
      form: {
         created_date: null,
         employee_id: null,
         start_date: null,
         vacation_system_d_id: null,
         days_count: null,
         notes: null,
      },
      exitDialog: false
   }),
   components: {
      LazyImage
   },
   computed: {
   },
   mounted() {
      this.calendar = [
         {
            id: 1,
            name: 'الهجري'
         },
         {
            id: 2,
            name: 'الميلادي'
         }
      ]
      this.pageData.isLoading = false;
      this.pageMainData()
      // this.getData()

   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`vacation_action/${this.$route.params.id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;
                     console.log("response", response);
                     // this.form = response.data.data;
                     !this.openProfileForm ? this.form = response.data.data : ""

                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }

                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`vacation_action/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;

                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`vacation_action/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      save_employee() {
         this.form.amount = this.form.increase_type == 2 ? this.$global.ConvertFromPercentage(this.increase_value, this.form.salary_fixed) : this.increase_value
         this.form.salary_after_increase = this.totalAmount
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     this.closeProfileForm()
                     this.refreshTable()
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`vacation_action/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
   watch: {
      "form.employee_id"() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`get_employee_vacation/${this.form.employee_id}`).then((response) => {
            if (response.check) {
               console.log("get_employee_vacation", response.data);
               this.pageData.isLoading = false;
               this.employee_data = response.data.vacation_balance;
               this.vacation_summary_name = this.$global.FilterArrayOfObjectElement(this.employee_data, 'id',
                  this.form.vacation_system_d_id, 'name')
            }
         })
      },
      valid() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid)
         }
      }
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
