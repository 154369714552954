<template>
  <section>
    <v-container fluid>

      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" createBtnText="add new company" />
      <!-- End Of Header -->

      <!-- Table -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key === 'employees_count'">
                <a @click="showEmployees(row.id)" class="text-decoration-underline">
                  {{ row.employees_count }}
                </a>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <!-- End Table -->

      <!-- Pagination -->
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
      <!-- End Pagination -->

    </v-container>
    
    <CompanyEmployeesDialog :openDialog="openDialog" v-if="openDialog" :closeDialog="() => openDialog = false"
      :row_id="row_id" />
  </section>
</template>

<script>
import CompanyEmployeesDialog from "./CompanyEmployeesDialog.vue";
import { EntityMixins } from "@/mixins/EntityMixins";

export default {
  name: "CompanyEntity",
  mixins: [EntityMixins],
  data: () => ({
    openDialog: false,
    row_id: 0
  }),
  components: {
    CompanyEmployeesDialog
  },
  computed: {
    tableHeader() {
      return [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "company name", key: "name", type: 'text', classes: "" },
        { text: "number of employees", key: "employees_count", type: 'slot', classes: "" },
        { text: "created by", key: "added_by", type: 'text', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ]
    }
  },
  mounted() {
    this.endpoint = "company";
    this.getData();
  },
  watch: {},
  methods: {
    setResponse(response) {
      console.log('response', response);
    },
    deleteMethod(id) {
      this.toggleActiveMethod(id);
    },
    restoreMethod(row) {
      this.toggleActiveMethod(row.id);
    },
    showEmployees(id) {
      this.row_id = id
      this.openDialog = true
    },
  },
};
</script>
