<template>
    <v-dialog v-model="openDialog" persistent width="550">
        <AnimatedLoading style="padding-top:10rem; height:475px" classes="white  ma-auto " :hideText="false"
            :isLoading="loading" v-if="loading" />
        <v-form ref="form" v-model="valid">
            <v-card v-if="!loading" class="py-4 px-2">
                <v-card-actions class="mb-5">
                    <v-card-text class="font-weight-bold text-h6 text-capitalize pa-0">
                        {{ $t(title) }}
                    </v-card-text>

                    <v-btn small fab color="red5" text @click="close">
                        <v-icon color="red5">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>

                <v-card-text class="pa-2">
                    <v-row>
                        <div :class="dialog_id == 8 ? 'choose-employee' : 'd-flex choose-employee'">

                            <GenericInput type="select" :lookups="employee" :value="form.employee_id"
                                @input="form.employee_id = $event" label="choose employee" :multi="false" :required="true"
                                selected_label="employee_name" selected_prop="id" :isLoading="false"
                                :cols="dialog_id == 3 ? [4, 4, 4] : [12, 12, 12]">
                            </GenericInput>

                            <GenericInput class="numberOfDays" v-if="dialog_id == 3" type="number"
                                :value="Math.abs(form.days_count)" @input="form.days_count = $event"
                                label="the number of days" :multi="false" :required="true" selected_label="name"
                                selected_prop="id" :isLoading="false" :cols="[4, 4, 4]">
                            </GenericInput>

                            <GenericInput v-if="dialog_id == 8" type="number" :value="Math.abs(form.installments_count)"
                                @input="form.installments_count = $event" label="number of installments" :multi="false"
                                selected_label="name" selected_prop="id" :isLoading="false" :required="true"
                                :cols="[12, 12, 12]">
                            </GenericInput>

                            <GenericInput v-if="dialog_id == 3 || dialog_id == 8" type="date" :value="form.start_date"
                                @input="form.start_date = $event" label="start date" :multi="false" selected_label="name"
                                selected_prop="id" :isLoading="false" :required="true"
                                :cols="dialog_id == 8 ? [12, 12, 12] : [4, 4, 4]">
                            </GenericInput>

                        </div>

                        <div class="d-flex justify-space-between return-time" v-if="dialog_id == 1">
                            <GenericInput type="time" :required="true" :value="form.from_time"
                                @input="form.from_time = $event" label="check out time" :isLoading="false"
                                :cols="[6, 6, 6]" />

                            <div class="d-flex ">
                                <label class="label mt-1" v-if="!form.return_again" @click="form.return_again = 1"
                                    style="cursor: pointer; margin-left:30px">
                                    {{ $t('possibility to return again') }}
                                </label>

                                <GenericInput type="time" v-if="form.return_again"
                                    :required="form.return_again ? true : false" :value="form.to_time"
                                    @input="form.to_time = $event" label="return time" :isLoading="false" :cols="[8, 8, 8]">
                                </GenericInput>

                                <div>
                                    <GenericInput class="mt-1" type="switch" :value="form.return_again"
                                        @input="form.return_again = $event" :label="''" :multi="false" :required="false"
                                        selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                                    </GenericInput>
                                </div>
                            </div>
                        </div>

                        <GenericInput v-if="dialog_id == 5" type="select" :lookups="allowances" :value="form.allowance_id"
                            @input="form.allowance_id = $event" label="name of allowance" :multi="false" :required="true"
                            selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                            <template v-slot:append>
                                <v-icon>mdi-magnify</v-icon>
                            </template>
                        </GenericInput>

                        <GenericInput v-if="dialog_id == 5" type="select" :lookups="allowance_type" :value="form.one_time"
                        @input="form.one_time = $event" label="type of allowance" :multi="false" :required="false"
                            selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]"
                            icon="mdi-chevron-down">
                        </GenericInput>
                        
                         <GenericInput v-if="dialog_id == 2" type="select" :lookups="hoursOfDelay"
                            :value="form.hours_late" @input="form.hours_late = $event"
                            label="number of hours of delay" :multi="false" :required="true" selected_label="number_of_hours"
                            :disabled="false" selected_prop="id" :isLoading="loadingVacation"
                            :cols="[12, 12, 12]" icon="mdi-chevron-down">
                        </GenericInput>

                        <GenericInput v-if="dialog_id == 4 || dialog_id == 5 || dialog_id == 8" type="number"
                            :value="Math.abs(form.amount)" @input="form.amount = $event" label="amount" :multi="false"
                            :required="true" selected_label="name" selected_prop="id" :isLoading="false"
                            :cols="[12, 12, 12]">
                        </GenericInput>

                        <GenericInput type="file" :value="form.attachment" @input="form.attachment = $event"
                            label="add attachment" :multi="false" :required="false" :isLoading="false" :cols="[12, 12, 12]">
                        </GenericInput>

                        <GenericInput v-if="dialog_id == 3" type="select" :lookups="vacation_types"
                            :value="form.vacation_system_d_id" @input="form.vacation_system_d_id = $event"
                            label="type of vacation" :multi="false" :required="true" selected_label="name"
                            :disabled="form.employee_id ? false : true" selected_prop="id" :isLoading="loadingVacation"
                            :cols="dialog_id == 3 ? [4, 4, 4] : [12, 12, 12]" icon="mdi-chevron-down">
                        </GenericInput>

                        <GenericInput class="" type="textarea" :rowsNumber="6" :value="form.notes"
                            @input="form.notes = $event" label="notes" :multi="false" selected_label="" :required="false"
                            selected_prop="id" :isLoading="false" :cols="dialog_id == 3 ? [8, 8, 8] : [12, 12, 12]">
                        </GenericInput>

                        <v-col class="text-end">
                            <v-btn class="error--text " :width="120" depressed @click="close">
                                {{ $t("back") | capitalize }}
                            </v-btn>
                            <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                                class="rounded-lg font-weight-bold white--text " :loading="saveDataLoading"
                                :style="$vuetify.rtl ? 'margin-right: 30px' : 'margin-left: 30px'">
                                {{ $t('request') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import GenericInput from '@/components/ui/GenericInput.vue';
export default {
    name: "RequestDialog",

    data: () => ({
        form: {
            from_time: null,
            to_time: null,
            hours_late: null,
            return_again: 0,
            days_count: null,
            start_date: null,
            vacation_system_d_id: null,
            amount: null,
            allowance_id: null,
            one_time: 1,
            employee_id: null,
            notes: null,
            permit_type: null,
            installments_count: null,
            attachment: null
        },
        allowance_type: [],
        hoursOfDelay: [],
        valid: false,
        loading: false,
        loadingVacation: false,
        saveDataLoading: false,
    }),
    computed: {
        endPoint() {
            return (dialog_id) => {
                switch (dialog_id) {
                    case 1:  // leave permission request
                    case 2:  // request permission delay
                        this.form.permit_type = dialog_id;
                        return 'make_permit_request';
                    case 3: return 'make_vacation_request';
                    case 4: return 'make_increase_request';
                    case 5: return 'make_allowance_request';
                    case 6: return 'make_resignation_request';
                    case 7: return 'make_termination_request';
                    case 8: return 'make_advance_request';
                }
            }
        }
    },

    methods: {
        close() {
            this.form.permit_type = null
            this.$refs.form.reset();
            this.closeDialog();
        },
        save() {
            this.loading = true
            this.form.to_time ? this.form.return_again = 1 : this.form.return_again = 0
            this.$api.POST_METHOD(`${this.endPoint(this.dialog_id)}`, this.$global.removeNullKeys({ ...this.form })).then((response) => {
                this.loading = false
                if (response.check) {
                    // this.printMethod(response, this.title);
                    this.close();
                }
            })
        },

    },
    watch: {
        openDialog() {
            this.$refs.form.reset();
        },
        "form.employee_id"() {
            if (this.form.employee_id !== null && this.dialog_id == 3) {
                this.loadingVacation = true
                this.$api.GET_METHOD(`get_employee_vacation/${this.form.employee_id}`).then((response) => {
                    if (response.check) {
                        this.loadingVacation = false
                        console.log("get_employee_vacation", response.data);
                        this.vacation_types = response.data.items;
                    }
                })
            }

        },
    },
    created() {
        this.allowance_type = [
            {
                id: 0,
                name: this.$i18n.t('monthly')
            },
            {
                id: 1,
                name: this.$i18n.t('one time')
            },
        ]
        this.hoursOfDelay = [
            {
                id: 1,
                number_of_hours: 1
            },
            {
                id: 2,
                number_of_hours: 2
            },
            {
                id: 3,
                number_of_hours: 3
            },
            {
                id: 4,
                number_of_hours:4
            },
        ]
    },
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        printMethod: { type: Function },
        title: { default: String },
        dialog_id: { type: Number },
        employee: { default: [] },
        allowances: { default: [] },
    },
    components: { GenericInput, AnimatedLoading }
}


</script>

<style lang="scss" scoped>
.choose-employee {
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.numberOfDays {
    margin-left: -10px;
}

.label {
    // width: 13rem;
    padding-top: 6px;
}

.return-time {
    width: 100%
}

.checkOut {
    border: 1px solid #ECEEF0;
    ;
    padding: 8px 30px;
    border-radius: 6px;
    margin-left: 1rem;
    font-size: 19px !important
}

.mr-n11 {
    left: 1rem !important;
    // right:30px
}
</style>
