
import CareersJobsEntity from "../views/HRModule/Careers/CareersJobs/CareersJobsEntity.vue"
import CareersJobsControl from "../views/HRModule/Careers/CareersJobs/CareersJobsControl.vue"

import InterviewEntity from "../views/HRModule/Careers/InterviewJobs/InterviewEntity.vue"
import InterviewControl from "../views/HRModule/Careers/InterviewJobs/InterviewControl.vue"


export const CareersRoutes = [

  // Careers Jobs
  {
    path: "/careers/jobs",
    name: "CareersJobsEntity",
    component: CareersJobsEntity,
    meta: {
      screen_code: "10-001",
    },
  },
  {
    path: "/careers/jobs-control",
    name: "CareersJobsControl",
    component: CareersJobsControl,
    meta: {
      screen_code: "10-001",
    },
  },
  {
    path: "/careers/jobs-control/:id",
    name: "CareersJobsControlEdit",
    component: CareersJobsControl,
    meta: {
      screen_code: "10-001",
    },
  },

  // Careers Interview 
  {
    path: "/careers/interview",
    name: "InterviewEntity",
    component: InterviewEntity,
    meta: {
      screen_code: "10-002",
    },
  },
  {
    path: "/careers/interview-control",
    name: "InterviewControl",
    component: InterviewControl,
    meta: {
      screen_code: "10-002",
    },
  },
  {
    path: "/careers/interview-control/:id",
    name: "InterviewControlEdit",
    component: InterviewControl,
    meta: {
      screen_code: "10-002",
    },
  },

];



