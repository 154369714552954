<template>
  <section id="careers_interview">
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showDeleted="false" :createBtn="false" :showSearch="false" />
      <!-- End Of Header -->

      <v-row>
        <AnimatedLoading :height="100" classes="mx-auto mt-16" :isLoading="pageData.isLoading" />
        <v-col v-show="!pageData.isLoading">
          <v-sheet height="64">
            <v-toolbar flat class="mx-3">
              <!-- navigation btn -->
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-{{ $i18n.locale == 'ar' ? 'right' : 'left' }}
                </v-icon>
              </v-btn>

              <!-- view month with date picker -->
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                :nudge-left="$i18n.locale == 'ar' ? '-60' : '60'" nudge-top="-7" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-toolbar-title v-if="$refs.calendar" v-bind="attrs" v-on="on" class="mx-2" @click="menu1 = !menu1">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                </template>
                <v-date-picker v-model="date" @change="value = date" no-title :locale="$i18n.locale"
                  @input="menu1 = false"></v-date-picker>
              </v-menu>

              <!-- navigation btn -->
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-{{ $i18n.locale == 'ar' ? 'left' : 'right' }}
                </v-icon>
              </v-btn>

              <!-- rest time  -->
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                {{ $t('Today') }}
              </v-btn>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="77dvh">
            <v-calendar @click="showEvent" :locale="$i18n.locale" category-show-all ref="calendar"
              @click:event="showEvent" v-model="value" :value="value" :events="events" color="primary" type="week">
              <template v-slot:day-body="{ date, week }">
                <div class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: nowY }"></div>
              </template>

            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog">
        <v-card height="100%">
          <v-card-text>
            <CareersDetailsAndCv :employeeData="employeeData" :isOpenFromInterView="true"
              :closeDialog="() => dialog = false" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import CareersDetailsAndCv from '../CareersJobs/CareersDetailsAndCv.vue';


export default {
  name: "InterviewEntity",

  data: () => ({
    pageData: {
      screen_code: "10-002",
      url: null,
      controlRoute: "careers/interview-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    employeeData: {},
    ready: false,
    menu1: false,
    dialog: false,
    today: '',
    value: '',
    focus: '',
    date: '',
    currentMonth: '',
    events: [],
  }),
  components: {
    AnimatedLoading,
    CareersDetailsAndCv
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    getCurrentMonthFromDate() {
      return (inputDate) => {
        const date = new Date(inputDate);
        const currentMonth = date.getMonth() + 1;
        return currentMonth;
      }
    }

  },
  watch: {
    value(current, prev) {
      console.log(current, prev);
      let currentMonth = this.getCurrentMonthFromDate(prev)
      let navigatedMonth = this.getCurrentMonthFromDate(current)
      if (prev && currentMonth != navigatedMonth) {
        this.currentMonth = navigatedMonth;
        this.getData()
      }
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();

    // this.$refs.calendar.checkChange()
    this.currentMonth = this.getCurrentMonthFromDate(new Date())
    this.getData();

    // handel current hour and date
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  methods: {
    showEvent(date) {
      console.log("date", date.event);
      this.employeeData = date.event
      this.employeeData.id = date.event.applicant_id
      this.dialog = true;
    },
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "Advertisement date", key: "advert_date", type: 'text', classes: "" },
        { text: "Expiry date", key: "expiry_date", type: 'text', classes: "" },
        { text: "interview name", key: "interview_name", type: 'text', classes: "" },
        { text: "Number of applicants", key: "application_counts", type: 'slot', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: false,
        edit: false,
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`interview?month=${this.currentMonth}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.ready = true
              let data = response.data.items.filter(item => item.start !== null && item.end !== null)
              data.forEach(item => {
                item.start = String(`${item.date} ${item.start}`);
                item.end = String(`${item.date} ${item.end}`);
              })

              this.events = data
              this.$store.state.sidebarIsOpen = true
              // this.events.push(response.data.items)
              console.log("this.events", data);
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
      this.value = this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    setToday() {
      this.value = this.$global.GetCurrentDate()
    },
    prev() {
      let navigatedMonth = this.getCurrentMonthFromDate(this.$refs.calendar.value)
      if (typeof this.$refs.calendar.value == 'string' && this.currentMonth != navigatedMonth) {
        this.currentMonth = navigatedMonth
        this.getData()
      }
      this.$refs.calendar.prev()
    },
    next() {
      let navigatedMonth = this.getCurrentMonthFromDate(this.$refs.calendar.value)
      if (typeof this.$refs.calendar.value == 'string' && this.currentMonth != navigatedMonth) {
        this.currentMonth = navigatedMonth
        this.getData()
      }
      this.$refs.calendar.next()
    },
    updateRange({ start, end }) {
      console.log('start, end', start, end);
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })
      }

      this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
};
</script>

<style lang="scss"  >
#careers_interview {
  .v-calendar-daily__head {
    // margin: unset !important;
    margin-right: unset !important;

  }

  .v-calendar .v-event-timed-container {
    margin-right: unset !important;
  }

  .v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
      content: '';
      position: absolute;
      background-color: #ea4335;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-top: -5px;
      margin-left: -6.5px;
    }
  }

  .v-menu__content.theme--light.menuable__content__active {
    min-width: auto !important;
    transform-origin: left top !important;
    z-index: 8 !important;
    top: 21% !important;
    left: 70% !important;
  }
}
</style>
