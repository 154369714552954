<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="!viewForm ? false : true"
            :deleteMethod="deleteMethod" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnClick="() => attachmentDialog = true" :editForm="pageData.isEdit && !viewForm"
            :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit" :loading="pageData.printLoading.loading" color="primary"
                  depressed height="40">{{
                     $t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Employees Profile -->
         <EmployeesProfile v-if="!viewForm" :employeeData="employeeData" :employeeDocs="employeeDocs"
            :callGetData="() => getData()" :isLoading="pageData.isLoading" />
         <!-- End Employees Profile -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 mt-4" v-if="viewForm">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-col cols="12" class="pa-0 px-10">
                  <v-tabs color="secondary" active-class="blue12" v-model="tab">
                     <v-tab class="font-weight-bold"><strong>{{ $t('personal information') }}</strong></v-tab>
                     <v-tab class="font-weight-bold"><strong>{{ $t('accounts') }}</strong></v-tab>
                  </v-tabs>
               </v-col>
               <v-col cols="12" class="pa-0">
                  <v-divider></v-divider>
               </v-col>
               <v-sheet class="pa-5">
                  <!--Personal Information Tab -->
                  <v-row align="center" class="mt-5" v-if="tab === 0">

                     <!--Profile Image -->
                     <v-col cols="2" class="pb-0 d-flex align-center flex-column">
                        <AvatarProfile :img="employee_image" :callBackMethod="callBackMethod" showDelete="true" />
                     </v-col>
                     <!--End Profile Image -->

                     <!--Name && phone && address & mail-->
                     <v-col cols="10">
                        <v-row align='center'>
                           <!--Name AR-->
                           <v-row>
                              <v-col cols="2" md="auto" lg="2">
                                 <span>{{ $t("name_ar") }}</span>
                              </v-col>
                              <GenericInput type="text" :value="form.employee_name_ar"
                                 @input="form.employee_name_ar = $event" label="first name" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 2, 2]">
                              </GenericInput>
                              <GenericInput type="text" :value="form.father_name_ar" @input="form.father_name_ar = $event"
                                 label="name of the father" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 2, 2]">
                              </GenericInput>

                              <GenericInput type="text" :value="form.grand_father_name_ar"
                                 @input="form.grand_father_name_ar = $event" label="grandfather name" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 2, 2]">
                              </GenericInput>

                              <GenericInput type="text" :value="form.family_name_ar" @input="form.family_name_ar = $event"
                                 label="family name" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 2, 2]">
                              </GenericInput>
                           </v-row>

                           <!--Name EN-->
                           <v-row>
                              <v-col cols="2" md="auto" lg="2">
                                 <span>{{ $t("name_en") }}</span>
                              </v-col>

                              <GenericInput type="text" :value="form.employee_name_en"
                                 @input="form.employee_name_en = $event" label="First Name" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 2, 2]">
                              </GenericInput>

                              <GenericInput type="text" :value="form.father_name_en" @input="form.father_name_en = $event"
                                 label="Father Name" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 2, 2]">
                              </GenericInput>

                              <GenericInput type="text" :value="form.grand_father_name_en"
                                 @input="form.grand_father_name_en = $event" label="Grandfather name" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 2, 2]">
                              </GenericInput>

                              <GenericInput type="text" :value="form.family_name_en" @input="form.family_name_en = $event"
                                 label="Family name" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 2, 2]">
                              </GenericInput>
                           </v-row>

                           <!--Phone && address & mail-->
                           <v-row align='center'>

                              <GenericInput type="number" :value="Math.abs(form.phone)" paddingX="px-0"
                                 @input="form.phone = $event" label="mobile number" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                                 <template v-slot:append>
                                    <div class="d-flex align-center">
                                       <span class="mx-2">+966</span>
                                       <img src="@/assets/img/svg/flag-saudi.svg" alt="" height="30">
                                    </div>
                                 </template>
                              </GenericInput>

                              <GenericInput type="email" :value="form.email" @input="form.email = $event" label="e-mail"
                                 :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                              </GenericInput>

                              <GenericInput type="text" :value="form.address" @input="form.address = $event"
                                 label="the address" padding-x="ps-0" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 10, 10]">
                              </GenericInput>
                           </v-row>
                        </v-row>
                     </v-col>
                     <!--End Name && phone && address & mail-->

                     <!--Company && management && department & qualification && job-->
                     <v-row class="mt-4">
                        <GenericInput type="select" :value="form.company_id" @input="form.company_id = $event"
                           label="the company" :lookups="companies" selected_label="name" :required="true"
                           selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 2, 2]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>

                        <GenericInput type="select" :value="form.management_id" @input="form.management_id = $event"
                           label="the management" :lookups="managements_filter ? managements_filter : managements"
                           selected_label="name" :required="true" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 2, 2]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>

                        <GenericInput type="select" :value="form.department_id" @input="form.department_id = $event"
                           label="the departments" :lookups="departments_filter ? departments_filter : departments"
                           selected_label="name" :required="true" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 2, 2]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>

                        <GenericInput type="select" :value="form.qualification_id" @input="form.qualification_id = $event"
                           label="the qualification" :lookups="qualifications" selected_label="name" :required="true"
                           selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 2, 2]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>

                        <GenericInput type="select" :value="form.job_id" @input="form.job_id = $event" label="position"
                           :lookups="jobs" selected_label="name" :required="true" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 2, 2]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>
                     </v-row>
                     <!--End Company && management && department & qualification && job-->

                     <!--Payment Method && vacation && finger print id -->
                     <v-row class="align-center">
                        <GenericInput type="select" :value="form.payment_method" @input="form.payment_method = $event"
                           label="salary payment method" selected_prop="id" :lookups="payment_method" selected_label="name"
                           :required="true" icon="mdi-chevron-down" :isLoading="pageData.editIsLoading" :cols="[12, 3, 2]">
                        </GenericInput>

                        <GenericInput type="select" :value="form.vacation_system_id"
                           @input="form.vacation_system_id = $event" label="vacation system" selected_prop="id"
                           :lookups="vacations" selected_label="name" :required="true" icon="mdi-chevron-down"
                           :isLoading="pageData.editIsLoading" :cols="[12, 3, 2]">
                        </GenericInput>

                        <GenericInput type="text" :value="form.employee_serial" @input="form.employee_serial = $event"
                           label="employee serial number" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 3, 2]">
                        </GenericInput>

                        <GenericInput type="number" :value="Math.abs(form.finger_print_id)"
                           @input="form.finger_print_id = $event" label="fingerprint code" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                        </GenericInput>

                        <v-col cols="12">
                           <v-divider></v-divider>
                        </v-col>
                     </v-row>
                     <!--End Payment Method && vacation && finger print id -->

                     <!--hiring date && immediately date && salary fixed && salary total -->
                     <v-row class="align-center">
                        <v-col cols="10" class="d-flex align-center">
                           <GenericInput type="date" :value="form.hiring_date" @input="form.hiring_date = $event"
                              label="Date of hiring" :required="true" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 6]">
                           </GenericInput>

                           <GenericInput type="date" :value="form.immediately_date" @input="form.immediately_date = $event"
                              label="last date immediately" :required="true" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 6]">
                           </GenericInput>
                        </v-col>

                        <v-col cols="12">
                           <v-divider></v-divider>
                        </v-col>
                     </v-row>
                     <!--End hiring date && immediately date && salary fixed && salary total -->

                     <!--Gender && marital status && religion && birthday && shift && nationality-->
                     <v-row class="align-center">

                        <GenericInput type="select" :value="form.gender" @input="form.gender = $event"
                           icon="mdi-chevron-down" label="gender" :lookups="gender" selected_label="name"
                           selected_prop="id" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                        </GenericInput>

                        <GenericInput type="select" :value="form.marital_status" @input="form.marital_status = $event"
                           selected_prop="id" icon="mdi-chevron-down" label="the marital status" :lookups="marital_status"
                           selected_label="name" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                        </GenericInput>

                        <GenericInput type="number" v-if="form.marital_status === 2"
                           :value="Math.abs(form.children_number)" @input="form.children_number = $event"
                           label="number of dependents" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 3, 3]">
                        </GenericInput>

                        <GenericInput type="select" :value="form.relegion" @input="form.relegion = $event"
                           selected_prop="id" icon="mdi-chevron-down" label="religion" :lookups="religion"
                           selected_label="name" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                        </GenericInput>

                        <GenericInput type="date" :value="form.birthday" @input="form.birthday = $event"
                           label="date of birth" :required="true" :isLoading="false" :cols="[12, 3, 3]">
                        </GenericInput>

                        <GenericInput type="select" :value="form.shift_id" @input="form.shift_id = $event"
                           label="official working" :lookups="shifts" selected_label="name" :required="true"
                           selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>

                        <GenericInput type="select" v-if="$route.params.data == 2 || form.nationality_id !== 2"
                           :value="form.nationality_id" @input="form.nationality_id = $event" label="nationality"
                           selected_prop="id" :lookups="nationalities" selected_label="name" :required="true"
                           icon="mdi-chevron-down" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                        </GenericInput>

                        <GenericInput type="textarea" :value="form.note" @input="form.note = $event" label="notes"
                           :required="false" :isLoading="false" :cols="[12, 3, 3]">
                        </GenericInput>

                        <GenericInput type="switch" id="employee_status" :value="form.is_active"
                           @input="form.is_active = $event" label="employee status" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                        </GenericInput>

                        <v-col cols="12">
                           <v-divider></v-divider>
                        </v-col>
                     </v-row>
                     <!--End Gender && marital status && religion && birthday && shift && nationality-->

                     <!--Username && password-->
                     <v-row>
                        <GenericInput type="text" :value="form.username" @input="form.username = $event" label="user name"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                        </GenericInput>
                        <GenericInput type="password" :value="form.password" @input="form.password = $event"
                           label="password" :required="pageData.isEdit ? false : true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 5, 5]">
                        </GenericInput>

                        <v-col cols="12">
                           <v-divider></v-divider>
                        </v-col>
                     </v-row>
                     <!--End username && password-->

                     <!--Animation loading-->
                     <v-col cols="12">
                        <animated-loading v-if="!saveClicked" :isLoading="pageData.isLoading" />
                     </v-col>

                     <!--Employee Documents-->
                     <v-row class="align-center" v-for="(employee_doc, i) in form.employee_documents" :key="i">
                        <GenericInput type="text" :value="form.employee_documents[i].number"
                           @input="form.employee_documents[i].number = $event" :label="employee_doc.label" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                        </GenericInput>

                        <GenericInput type="date" :value="form.employee_documents[i].start_date"
                           @input="form.employee_documents[i].start_date = $event" label="start date" :required="true"
                           :isLoading="false" :cols="[12, 2, 2]">
                        </GenericInput>

                        <v-col cols="12" md="auto">
                           <v-icon>mdi-minus</v-icon>
                        </v-col>

                        <GenericInput type="date" :value="form.employee_documents[i].end_date"
                           @input="form.employee_documents[i].end_date = $event"
                           :minDate="form.employee_documents[i].start_date" label="end date" :required="true"
                           :isLoading="false" :cols="[12, 2, 2]">
                        </GenericInput>

                        <!--Upload button-->
                        <v-col cols="12" md="auto">
                           <div
                              :class="!employee_doc.doc_file && saveClicked ? 'upload_pictures_warning text-center' : 'upload_pictures text-center'">
                              <v-btn :class="!employee_doc.doc_file ? 'primary--text blue12' : 'primary--text transparent'"
                                 elevation="0">
                                 {{ !employee_doc.doc_file ? $t("upload pictures") : $t("uploaded") }}
                                 <GenericInput type="dropzone" class="userUploadImage"
                                    :value="form.employee_documents[i].doc_file"
                                    @input="form.employee_documents[i].doc_file = $event" label="image" :required="true"
                                    :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                 </GenericInput>
                                 <v-icon class="mx-2" v-if="!employee_doc.doc_file">mdi-image</v-icon>
                              </v-btn>
                           </div>
                        </v-col>
                        <!--End Upload button-->

                        <!--Warning Upload button-->
                        <v-col cols="auto" v-if="!employee_doc.doc_file && saveClicked">
                           <v-icon class="mx-2 red--text">mdi-close</v-icon>
                        </v-col>
                     </v-row>
                     <!--End Employee Documents-->
                  </v-row>
                  <!--End Personal Information Tab -->

                  <!--Accounts Tab -->
                  <v-row class="align-center py-3" v-if="tab === 1">
                     <v-col cols="12" md="12">
                        <h2>{{ $t("linking to accounts") }}</h2>
                     </v-col>

                     <v-col cols="12" md="12" class="d-flex align-center">
                        <v-col cols="12" md="2">{{ $t("payroll expense account") }}</v-col>

                        <GenericInput type="select" :value="form.salary_account" @input="form.salary_account = $event"
                           selected_prop="id" label="linked account" :lookups="accounts" selected_label="account_name"
                           :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>
                     </v-col>

                     <v-col cols="12" md="12" class="d-flex align-center">
                        <v-col cols="12" md="2">{{ $t("discounts account") }}</v-col>

                        <GenericInput type="select" :value="form.deduction_account"
                           @input="form.deduction_account = $event" selected_prop="id" label="linked account"
                           :lookups="accounts" selected_label="account_name" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>
                     </v-col>

                     <v-col cols="12" md="12" class="d-flex align-center">
                        <v-col cols="12" md="2">{{ $t("advances account") }}</v-col>

                        <GenericInput type="select" :value="form.advance_account" @input="form.advance_account = $event"
                           selected_prop="id" label="linked account" :lookups="accounts" selected_label="account_name"
                           :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                           <template v-slot:append>
                              <v-icon>mdi-magnify</v-icon>
                           </template>
                        </GenericInput>
                     </v-col>

                  </v-row>
                  <!--End Accounts Tab -->
               </v-sheet>
            </v-card>

         </v-form>
         <!-- End of Form -->
      </v-container>
      <EmployeePrint :printAction="() => print = false" :print="print" :printData="printData"
         :printStaticData="printStaticData" />
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import EmployeesProfile from "./EmployeesProfile.vue";
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import AvatarProfile from '@/components/ui/AvatarProfile.vue';
import EmployeePrint from "./EmployeePrint.vue";

export default {
   name: "EmployeesControl",

   data: () => ({
      pageData: {
         screen_code: "09-004",
         url: null,
         controlRoute: "/codes/employee-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      viewForm: false,
      valid: false,
      employee_image_change: false,
      tab: 0,
      employeeData: {},
      employeeDocs: null,
      employee_image: null,
      form: {
         employee_image: null,
         employee_name_ar: null,
         employee_name_en: null,
         father_name_ar: null,
         father_name_en: null,
         grand_father_name_ar: null,
         grand_father_name_en: null,
         family_name_en: null,
         family_name_ar: null,
         marital_status: null,
         address: null,
         gender: null,
         birthday: null,
         relegion: null,
         phone: null,
         email: null,
         children_number: 0,
         shift_id: null,
         username: null,
         password: null,
         company_id: null,
         finger_print_id: null,
         management_id: null,
         department_id: null,
         qualification_id: null,
         payment_method: null,
         note: null,
         vacation_system_id: null,
         job_id: null,
         nationality_id: null,
         employee_type: null,
         advance_account: null,
         deduction_account: null,
         salary_account: null,
         employee_serial: null,
         employee_documents: [],
         hiring_date: null,
         immediately_date: null,
         is_active: null,
      },
      attachmentDialog: false,
      saveClicked: false,
      payment_method: [],
      gender: [],
      marital_status: [],
      religion: [],
      companies: [],
      managements: [],
      managements_filter: [],
      departments: [],
      departments_filter: [],
      qualifications: [],
      jobs: [],
      vacations: [],
      shifts: [],
      nationalities: [],
      documents: [],
      accounts: [],
      documents_name: null,
      printData: [],
      printStaticData: Object,
      print: false,
      printResponse: {},
   }),
   components: {
      GenericInput,
      ControlHeader,
      EmployeesProfile,
      AnimatedLoading,
      AvatarProfile,
      EmployeePrint
   },
   computed: {
      attach_employee() {
         if (this.documents) {
            return this.documents.map(e => {
               this.form.employee_documents.push({
                  document_id: e.id,
                  number: null,
                  start_date: null,
                  end_date: null,
                  doc_file: null,
                  label: e.name,
               })
            })
         } else {
            return false
         }
      },
      file_validate() {
         if (!this.pageData.isEdit) {
            return this.form.employee_documents.every(e => {
               if (e.doc_file !== null) {
                  return true
               } else {
                  return false
               }
            })
         } else if (this.pageData.isEdit) {
            return true
         } else {
            return false
         }
      }

   },
   mounted() {
      this.pageMainData()
      this.employee_type()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
         this.payment_method = [
            {
               id: 1,
               name: this.$i18n.t('my bank')
            },
            {
               id: 2,
               name: this.$i18n.t('cash')
            },
            {
               id: 3,
               name: this.$i18n.t('the transfer')
            }
         ]
         this.gender = [
            {
               id: 1,
               name: this.$i18n.t('male')
            },
            {
               id: 2,
               name: this.$i18n.t('female')
            }
         ]
         this.marital_status = [
            {
               id: 1,
               name: this.$i18n.t('single')
            },
            {
               id: 2,
               name: this.$i18n.t('married')
            }
         ]
         this.religion = [
            {
               id: 1,
               name: this.$i18n.t('muslim')
            },
            {
               id: 2,
               name: this.$i18n.t('christian')
            },
            {
               id: 3,
               name: this.$i18n.t('otherwise')
            }
         ]
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               console.log("this.form", this.form);
               this.$api.GET_METHOD(`employee/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.accounts = response.data.accounts;
                     this.printResponse = response.data;
                     this.employeeData = response.data.data;
                     this.companies = response.data.companys;
                     this.managements = response.data.managements;
                     this.departments = response.data.departments;
                     this.qualifications = response.data.qualifications;
                     this.jobs = response.data.jobs;
                     this.vacations = response.data.vacations;
                     this.shifts = response.data.shifts;
                     if (response.data.data.employee_type == 1) {
                        console.log("employee_type");
                        this.nationalities = [{
                           id: response.data.data.nationality_id,
                           name: response.data.data.nationality_name
                        }]
                     } else {
                        this.nationalities = response.data.nationalitys;
                     }
                     this.documents = response.data.documents;
                     this.form = response.data.data;
                     this.employee_image = response.data.data.employee_image
                     this.employeeDocs = response.data.emp_docs;
                     this.$route.params.data === 1 ? this.form.nationality_id = this.nationalities[0].id : this.form.nationality_id
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            !this.$route.params.data ? this.$router.push(this.pageData.url) : ""
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`employee/create?employee_type=${this.$route.params.data}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("employee_type", response);
                     this.accounts = response.data.accounts;
                     this.companies = response.data.companys;
                     this.managements = response.data.managements;
                     this.departments = response.data.departments;
                     this.qualifications = response.data.qualifications;
                     this.jobs = response.data.jobs;
                     this.vacations = response.data.vacations;
                     this.shifts = response.data.shifts;
                     this.nationalities = response.data.nationalitys;
                     this.documents = response.data.documents;
                     this.attach_employee
                     this.$route.params.data === 1 ? this.form.nationality_id = this.nationalities[0].id : this.form.nationality_id
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.saveClicked = true
         this.$refs.form.validate()
         if (this.valid && this.file_validate) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`employee/${this.form.id}`, this.form).then((response) => {
                  console.log('save', response);
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`employee`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      printMethod() {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = this.printResponse.data;
         this.printStaticData.contract = this.printResponse.contract_m;
         this.printStaticData.contract_d = this.printResponse.contract_d;
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`employee/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      employee_type() {
         this.form.employee_type = this.$route.params.data

      },
      callBackMethod(img) {
         // console.log("img", img.substring(0, 4));
         console.log("img", img);
         // for view image in avatar profile
         this.employee_image = img
         // for update form with image
         this.form.employee_image = img
         // for update DOM to view changes
         this.form = { ...this.form }
         console.log("form", this.form);
      }
   },
   watch: {
      'form.company_id'(oldValue, newValue) {
         console.log("oldValue,newValue", oldValue, newValue);
         this.managements_filter = this.managements.filter(e => e.company_id === this.form.company_id)
         if (!this.pageData.isEdit) {
            this.form.management_id = null
         }
      },
      'form.management_id'() {
         this.departments_filter = this.departments.filter(e => e.management_id === this.form.management_id)
      },
   },
};
</script>

<style lang="scss" scoped>
.image-input div img {
   width: 100%;
   border-radius: 50%;
}

.image-input {
   display: block;
   height: 110px;
   width: 112px;
   cursor: pointer;
   border-radius: 50%;
   background-size: 100% 100%;
   background-position: center center;
   margin: auto;
   z-index: 1;
}

.file-input {
   display: none
}

img.d-block.ma-auto {
   max-height: 200px;
   width: 120px;
   border-radius: 50%;
   position: absolute;
   top: 129px;
}

.userUploadImage {
   position: absolute;
   top: -6px;
   padding: 0;
   opacity: 0;
   height: 39px;
   width: 100%;
}

.avatar {
   margin: auto;

   .profile_img_container {
      overflow: hidden !important;
      position: relative;
   }

   input {
      cursor: pointer;
      border-radius: 33%;
      height: 110px;
      width: 207px;
      position: absolute;
      left: 0;
      opacity: 0;
   }
}

.upload_pictures {

   cursor: pointer;
   overflow: hidden;

   span {
      overflow: hidden;
   }

   input {
      border-radius: 9px;
      height: 43px;
      width: 250px;
      position: absolute;
      left: -18px;
      opacity: 0;
      cursor: pointer;
   }
}

.upload_pictures_warning {
   outline: 2px solid red;
   border-radius: 10px;
}
</style>
