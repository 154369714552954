<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!openProfileForm" :pageData="pageData" :form="form"
            :createBtn="pageData.isEdit ? false : true" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
            :editForm="false" :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit"
                  :loading="pageData.printLoading.loading || pageData.editIsLoading" color="primary" depressed
                  height="40">{{
                     $t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- pageData.isEdit && !viewForm -->
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !openProfileForm">
            <template v-slot:summerySlot="{ item }">
               <v-row>
                  <v-divider vertical></v-divider>
                  <div v-if="item.key == 'slotKey'" class="ma-10">
                     <v-card class="pa-7 my-4" width="500" color="blue12" elevation="0">
                        <v-row class="justify-space-between text-center">
                           <v-col cols="12" md="4" color="blue12">
                              <h4 class="mb-3">{{ $t("previous salary") }}</h4>
                              <span>{{ resData.previous_salary | float }}</span>
                           </v-col>
                           <v-col cols="12" md="4">
                              <h4 class="mb-3">{{ $t("the increase") }}</h4>
                              <span>{{ totalAmount | float }}</span>
                           </v-col>
                           <v-col cols="12" md="4">
                              <h4 class="mb-3">{{ $t("salary after increase") }}</h4>
                              <span v-if="totalAmount > 0">{{ resData.previous_salary + resData.amount | float }}</span>
                           </v-col>
                        </v-row>
                     </v-card>
                  </div>
               </v-row>
            </template>
         </ControlSummery>
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 mt-5" v-if="viewForm || openProfileForm">
            <v-card class="transparent  overflow-hidden py-5" elevation="0">
               <v-row class="align-center">
                  <GenericInput type="date" v-if="!pageData.isEdit" :value="created_date = $global.GetCurrentDate()"
                     @input="created_date = $event" label="date" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="this.openProfileForm ? [12, 6, 4] : [12, 6, 3]" :disabled="true">
                  </GenericInput>

                  <GenericInput type="select" v-if="!this.openProfileForm" :value="form.employee_id"
                     @input="form.employee_id = $event" icon="mdi-chevron-down" label="employee" :lookups="employee"
                     selected_prop="id" selected_label="employee_name" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="this.pageData.isEdit ? [12, 6, 4] : [12, 6, 3]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="increase_type_tree" :value="form.increase_type"
                     @input="form.increase_type = $event" icon="mdi-chevron-down" label="increase type" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="this.openProfileForm ? [12, 6, 4] : [12, 6, 3]">
                  </GenericInput>

                  <GenericInput :type="form.increase_type == 2 ? 'percentage' : 'number'" :value="Math.abs(increase_value)"
                     @input="increase_value = $event" label="value/ratio" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="this.openProfileForm ? [12, 6, 4] : [12, 6, 3]">
                  </GenericInput>

               </v-row>

               <v-row class="align-center">
                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="details"
                     :required="false" :rowsNumber="8" :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                  </GenericInput>

                  <v-col cols="auto" id="divider_ver" class="d-none d-lg-flex">
                     <v-divider vertical></v-divider>
                  </v-col>

                  <v-col cols="12" md="7" lg="auto">
                     <v-card class="my-4" width="500" color="blue12" elevation="0">
                        <v-row class="justify-space-between text-center pa-9">
                           <v-col cols="12" md="4" class="pa-0" color="blue12">
                              <h4 class="mb-7">{{ $t("previous salary") }}</h4>
                              <span>{{ salary_fixed + increaseDiffrence | float }}</span> <br>
                           </v-col>
                           <v-col cols="12" md="4" class="pa-0">
                              <h4 class="mb-7">{{ $t("the increase") }}</h4>
                              <span>{{ totalAmount | float }}</span>
                           </v-col>
 
                           <v-col cols="12" md="4" class="pa-0">
                              <h4 class="mb-7">{{ $t("salary after increase") }}</h4>
                              <span>{{ totalSalary | float }} </span>
                           </v-col>
                        </v-row>
                     </v-card>
                  </v-col>

               </v-row>
            </v-card>
         </v-form>

         <!-- End of Form -->
      </v-container>
      <SalaryIncreasePrint :printAction="() => print = false" :print="print" :printData="printData"
         :printStaticData="printStaticData" />
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import SalaryIncreasePrint from "./SalaryIncreasePrint.vue";
// import AttachmentDialog from "@/components/modals/AttachmentDialog.vue";

export default {
   name: "SalaryIncreaseControl",

   data: () => ({
      pageData: {
         screen_code: "09-012",
         url: null,
         controlRoute: "/transactions/salary_increase-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      viewForm: false,
      summery: [],
      valid: false,
      tab: null,
      increase_type_tree: [],
      employee: [],
      increases: [],
      openDialog: false,
      attachmentDialog: false,
      increase_value: 0,
      salary_total: 0,
      salary_fixed: 0,
      send_amount: 0,
      increase_type: null,
      created_date: null,
      form: {
         increase_type: null,
         amount: null,
         notes: null,
         employee_id: null,
         previous_salary: null,

      },
      printResponse: {},
      resData: {},
      printData: [],
      printStaticData: Object,
      print: false,
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      SalaryIncreasePrint
   },
   computed: {
      totalAmount() {
          if (this.form.increase_type == 1) {
            console.log('value',);
            return Number(this.increase_value)
            
         }
         if (this.form.increase_type == 2) {
            console.log('%',);
            return Number(this.increase_value / 100) * Number(this.salary_fixed + this.increaseDiffrence)
         }
         else {
            return 0
         }

      },
      totalSalary() {
         if (this.totalAmount > 0) {
            return this.salary_fixed + this.totalAmount + this.increaseDiffrence
         }
         else {
            return 0
         }
      },
      send_employee_data() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid, this.totalAmount, this.totalSalary, this.$route.params.id, `${this.salary_fixed + this.increaseDiffrence}`)
         } else {
            return false
         }
      },
      increaseDiffrence() {
         if (this.form.employee_id && this.increases.length > 0) {
            const increase = this.increases.find(inc => inc.employee_id === this.form.employee_id);

            if (increase) {
               return increase.amount;
            }
         }

         return 0; // Default value if no matching increase is found
      },


   },
   mounted() {
      this.pageMainData()
      // this.openProfileForm ? this.form.employee_id = +this.$route.params.id : this.getData()
      this.getData()
   },

   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;

         this.increase_type_tree = [
            {
               id: 1,
               name: this.$i18n.t('Value')
            },
            {
               id: 2,
               name: this.$i18n.t('ratio')
            },
         ]
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`salary_increase/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employee = response.data.employees;
                     this.resData = response.data.data
                     this.form = response.data.data
                     this.salary_fixed = response.data.data.salary_fixed
                     this.printResponse = response.data
                     // convert Percentage to value
                     if (response.data.data.increase_type == 2) {
                        this.increase_value = this.ConvertToPercentage(response.data.data.amount, response.data.data.salary_fixed)
                     } else {
                        this.increase_value = response.data.data.amount
                     }
                     console.log("salary_increase", response);
                     this.increase_type = this.$global.FilterArrayOfObjectElement(this.increase_type_tree, "id", response.data.data.increase_type, "name")
                     this.summery = [
                        {
                           label: 'employee',
                           value: response.data.data.employee_name || '-',
                           class: '',
                           cols: 12, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'date',
                           value: response.data.data.add_date || '-',
                           class: '',
                           cols: 12, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'increase type',
                           value: this.increase_type || '-',
                           class: '',
                           cols: 12, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: this.increase_type,
                           value: this.increase_value || '-',
                           class: '',
                           cols: 12, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'details',
                           value: response.data.data.notes || '-',
                           class: '',
                           cols: 12, sm: null, md: null, lg: 5, xl: null,
                        },
                        {
                           label: '',
                           key: 'slotKey',
                           value: response.data.data || '-',
                           type: 'slot',
                           class: '',
                           cols: 12, sm: null, md: null, lg: 7, xl: null,
                        },



                     ]
                     this.viewSummery = true;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
                this.viewForm = true;
               this.$api.GET_METHOD(`salary_increase/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employee = response.data.employees;
                     this.increases = response.data.increases;
                     console.log('increases', this.increases);

                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                        this.salary_fixed = this.employee.filter(employee => this.form.employee_id === employee.id)[0].salary_fixed
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }

         }
      },
      save() {
         // this.form.amount = Number(this.increase_value);
         // this.form.amount = this.form.increase_type == 2 ? this.$global.ConvertFromPercentage(this.increase_value, this.salary_fixed) : this.increase_value
         this.form.amount = this.totalAmount
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.form.previous_salary = this.salary_fixed + this.increaseDiffrence
               this.$api.POST_METHOD(`salary_increase/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.form.previous_salary = this.salary_fixed + this.increaseDiffrence
               this.$api.POST_METHOD(`salary_increase`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`salary_increase/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      ConvertToPercentage(PartialValue, TotalValue) {
         return ((100 * PartialValue) / TotalValue).toFixed(0);
      },
      printMethod() {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = { ...this.printResponse.data };
         this.printStaticData.master.increase_value = this.increase_value
         this.printStaticData.master.increase_type = this.increase_type
         this.printStaticData.contract = this.printResponse.contract_m;
         this.printStaticData.contract_d = this.printResponse.contract_d;
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },
   },
   watch: {
      'form.increase_type'() {
         if (this.viewForm) {
            this.increase_value = ""
         }
      },

      'form.employee_id'() {
         if (this.viewForm && !this.openProfileForm) {
            console.log('if viewForm', this.salary_fixed);
            this.salary_fixed = this.employee.filter(employee => this.form.employee_id === employee.id)[0].salary_fixed
         }
      },
   },
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function }
   },
   updated() {
      this.send_employee_data
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 247px !important;
}
</style>
