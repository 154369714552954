<template>
  <section id="careers_jobs">
    <v-container fluid>
      <!-- Tabs -->
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab class="transparent">{{ $t('details') }}</v-tab>
        <v-tab class="transparent">{{ $t('the biography') }}</v-tab>
        <v-spacer></v-spacer>
        <div class="d-flex align-center" @click="closeDialog" v-if="isOpenFromInterView">
          <v-icon color="red5" class="cursor_pointer">mdi mdi-close-thick</v-icon>
        </div>
      </v-tabs>

      <v-divider></v-divider>

      <AnimatedLoading :height="100" classes="mx-auto mt-16" :isLoading="getCvAndDetailsLoading" />

      <div v-if="tab == 0 && !getCvAndDetailsLoading" class="mt-3">
        <!-- avatar card -->
        <v-card class="overflow-auto mx-auto my-2" color="gray12" width="100%" elevation="0">
          <v-list-item three-line>
            <v-avatar width="18%" height="18%">
              <LazyImage :height="120" :src="$api.serverUrl + cvData.data?.image"
                :error="require('@/assets/img/svg/default_profile.svg')"
                :loading="require('@/assets/img/svg/default_profile.svg')" />
            </v-avatar>

            <v-list-item-content class="pa-2">
              <div class="d-flex justify-space-between overflow-x-auto overflow-y-hidden">
                <div>
                  <div class="d-flex mb-4 align-center pt-2">
                    <!-- Old -->
                    <span class="mx-2 gray6--text text-caption font-weight-bold">
                      ({{ $global.CalculateAge(cvData.data?.birth_date) }} {{ $t('Year') }})
                      {{ cvData.data?.birth_date }}</span>

                    <!-- Gender -->
                    <span class="mx-1" v-if="cvData.data?.gender">
                      <v-img v-if="cvData.data?.gender == 1" height="22" width="22"
                        src="@/assets/img/svg_icons/male.svg"></v-img>
                      <v-img v-if="cvData.data?.gender == 2" height="22" width="22"
                        src="@/assets/img/svg_icons/female.svg"></v-img>
                    </span>

                    <!-- Rate -->
                    <div v-if="cvData.data?.rates" class="d-flex align-center gray2--text" elevation="0">
                      <span class="mx-1">{{ (updatedRates || cvData.data?.rates?.overall) }}</span>
                      <span>
                        <v-img src="@/assets/img/svg_icons/rate.svg"></v-img>
                      </span>
                    </div>
                  </div>

                  <span v-if="cvData.data?.full_name" class="text-body-1 font-weight-bold">
                    {{ cvData.data?.full_name }}</span>
                </div>

                <div>
                  <!-- Take Action -->
                  <v-btn @click="handelRateDialog" min-width="46" height="30" class="orange12 orange3--text mx-2 my-1"
                    elevation="0">
                    <span class="mx-1">{{ $t('reviews') }}</span>
                  </v-btn>

                  <v-btn @click="handelMessageDialog('reject')" v-if="cvData.data?.status !== 4" min-width="46"
                    height="30" class="gray11 red2--text mx-2 my-1" elevation="0">
                    <span class="mx-1">{{ $t('reject') }}</span>
                  </v-btn>

                  <v-btn @click="handelMessageDialog('acceptance')"
                    v-if="!isOpenFromInterView && cvData.data?.status !== 0 && cvData.data?.status !== 1 && cvData.data?.status !== 4 && cvData.data?.status !== 5 && cvData.data?.status !== 6"
                    min-width="46" height="30" class="green9 gray2--text mx-2 my-1" elevation="0">
                    <span class="mx-1">{{ $t('Acceptance') }}</span>
                  </v-btn>

                  <v-btn @click="handelMessageDialog('interview')"
                    v-if="(cvData.data?.status === 1 || cvData.data?.status === 0) && cvData.data?.status !== 4 && cvData.data?.status !== 5"
                    min-width="46" height="30" class="green9 gray2--text mx-2 my-1" elevation="0">
                    <span class="mx-1">{{ $t('interview') }}</span>
                  </v-btn>

                  <v-btn @click="suspendingAction" v-if="isOpenFromInterView" :loading="suspendingActionLoading"
                    min-width="46" height="30" class="yellow10 gray2--text mx-2 my-1" elevation="0">
                    <span class="mx-1">{{ $t('suspending') }}</span>
                  </v-btn>

                </div>
              </div>

              <div class="d-flex justify-space-between overflow-x-auto overflow-y-hidden">
                <div class="font-weight-bold">
                  <a class="blue6--text text-decoration-underline" href="/" target="_blank">{{ cvData.data?.mobile }}</a>
                  <v-icon class="mt-3" small>mdi mdi-circle-small</v-icon>
                  <a class="blue6--text text-decoration-underline" :href="`mailto:${cvData.data?.email}`" target="_blank">
                    {{ cvData.data?.email }}</a>
                </div>

                <div class="pa-2 d-flex justify-space-between">
                  <a class="px-1" v-if="cvData.data?.portfolio" :href="`https://${cvData.data?.portfolio}`"
                    target="_blank"><v-icon color="gray2">mdi mdi-web</v-icon></a>
                  <a :href="`https://${cvData.data?.linked_in}`" target="_blank"><v-icon color="blue6">mdi
                      mdi-linkedin</v-icon></a>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <!-- Expected Salary && Experience -->
        <v-card class="pa-5 my-5" color="gray12" width="100%" elevation="0">
          <v-row class="pa-2">
            <v-col cols="12" md="4" class="gray6--text">
              <div>{{ $t('Number of years of experience') }}</div>
              <div class="font-weight-bold mt-4">{{ cvData.data?.experience_years }}</div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="4" class="gray6--text">
              <div>{{ $t('current salary') }}</div>
              <div class="font-weight-bold mt-4">{{ cvData.data?.current_salary | float }}</div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="4" class="gray6--text">
              <div>{{ $t('Expected Salary') }}</div>
              <div class="font-weight-bold mt-4">{{ cvData.data?.expected_salary | float }}</div>
            </v-col>
          </v-row>
        </v-card>

        <!-- Eduction -->
        <v-card class="pa-5 my-5" color="gray12" width="100%" elevation="0">
          <v-row justify="center">
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header color="gray12 gray6--text font-weight-bold">
                  {{ $t('Eduction') }}</v-expansion-panel-header>
                <v-expansion-panel-content color="gray12">
                  <div class="font-weight-bold">
                    <span>{{ cvData.data?.university }}</span>
                    <v-icon class="mt-3" small v-if="cvData.data?.university">mdi mdi-circle-small</v-icon>
                    <span>{{ cvData.data?.college }}</span>

                    <span class="mr-3 gray6--text subtitle-2">{{ cvData.data?.education_date }}</span>
                    <span class="gray6--text subtitle-2 mx-2" v-if="cvData.data?.education_date">{{ '-' }}</span>
                    <span class="gray6--text subtitle-2">{{ cvData.data?.graduate_date }}</span>
                  </div>

                  <div class="mt-3">
                    {{ cvData.data?.qualification }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card>

        <!-- Previous experience -->
        <v-card class="pa-5 overflow-y-auto" color="gray12" max-height="500" width="100%" elevation="0">
          <v-row justify="center">
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header color="gray12 gray6--text font-weight-bold">
                  {{ $t('Previous experience') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content color="gray12" v-for="(experience, i) in cvData.data?.latest_experience"
                  :key="experience.id">
                  <div class="d-flex font-weight-bold">
                    <span>{{ i + 1 }}-</span>
                    <span>{{ experience?.company }}</span>

                    <div class="mx-2">
                      <span class="gray6--text subtitle-2">{{ experience?.from_date }}</span>
                      <span class="gray6--text subtitle-2 mx-2">{{ '-' }}</span>
                      <span class="gray6--text subtitle-2">{{ experience?.to_date }}</span>
                    </div>
                  </div>

                  <div class="mt-3">
                    {{ experience?.description }}
                  </div>
                  <v-divider v-if="i !== cvData.data.latest_experience.length - 1" class="mt-3"></v-divider>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card>
      </div>


      <v-alert v-if="tab == 1 && cvData.data?.duplicate_applicant && !getCvAndDetailsLoading" text color="error"
        class="text-center mt-3" dense>
        <span> {{ $t('This applicant applied to the company before on') }}
          {{ cvData.data?.duplicate_applicant_date }}</span>
        <v-btn @click="blockApplicant" min-width="46" height="30" :loading="blockApplicantLoading"
          class="red5 white--text mx-2" elevation="0">
          <span>{{ $t('Block this applicant') }}</span>
        </v-btn>
      </v-alert>

      <div v-if="tab == 1 && !getCvAndDetailsLoading" class="fullscreen_cv mt-3" style="height: 100dvh;" id="cv_preview">
        <div id="fullscreen" class="fullscreen_cv" v-fullscreen="full_screen_options">
          <v-icon class="cursor_pointer" color="white" v-if="!fullscreen">mdi mdi-fullscreen</v-icon>
          <v-icon class="cursor_pointer" color="white" v-if="fullscreen">mdi mdi-fullscreen-exit</v-icon>
        </div>

        <!-- iframe -->
        <iframe :src="$api.serverUrl + cvData.data?.cv_file" width="100%" height="100%" frameborder="0"></iframe>
        <!-- iframe -->
      </div>
    </v-container>

    <MessageDialog :openDialog="messageDialog" :closeDialog="() => messageDialog = false" :clickedData="clickedData" />

    <RateDialog :openDialog="rateDialog" :closeDialog="() => rateDialog = false" :rates="cvData"
      :updateRates="updateRates" />

  </section>
</template>
  
<script>
import LazyImage from "@/components/ui/LazyImage.vue";
import MessageDialog from './MessageDialog.vue';
import RateDialog from "./RateDialog.vue";
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';

export default {
  name: "CareersDetailsAndCv",

  data: () => ({
    pageData: {
      screen_code: "10-001",
      url: null,
      controlRoute: "careers/jobs-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: "?",
    },
    tab: null,
    messageDialog: false,
    rateDialog: false,
    fullscreen: false,
    updatedRates: null,
    getCvAndDetailsLoading: false,
    blockApplicantLoading: false,
    suspendingActionLoading: false,
    clickedData: {},
    cvData: {},
    pdfUrl: '../../../../../CV-Frontend Developer.pdf',
  }),
  props: {
    employeeData: { default: Object },
    closeDialog: { default: Function },
    isOpenFromInterView: { default: false }
  },
  components: {
    LazyImage,
    MessageDialog,
    RateDialog,
    AnimatedLoading
  },
  computed: {
    full_screen_options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
        target: ".fullscreen_cv",
      };
    },
  },
  watch: {
    employeeData() {
      console.log("yes", this.employeeData);
      this.getCvAndDetails();
    }
  },
  mounted() {
    this.getCvAndDetails();
  },
  methods: {
    getCvAndDetails() {
      if (this.employeeData.id) {
        this.getCvAndDetailsLoading = true;
        this.$api.GET_METHOD(`applicant/${this.employeeData.id}`).then((response) => {
          this.getCvAndDetailsLoading = false;
          if (response.check) {
            console.log("applicant", response);
            this.cvData = response.data
            this.updatedRates = null
          }
        });
      }
    },
    blockApplicant() {
      this.blockApplicantLoading = true;
      this.$api.POST_METHOD(`applicant_blocked/${this.employeeData.id}`).then((response) => {
        this.blockApplicantLoading = false;
        if (response.check) {
          console.log("applicant", response);
        }
      });
    },
    suspendingAction() {
      this.suspendingActionLoading = true;
      this.$api.POST_METHOD(`applicant_pending/${this.employeeData.id}`).then((response) => {
        this.suspendingActionLoading = false;
        if (response.check) {
          console.log("applicant_pending", response);
        }
      });
    },
    handelMessageDialog(data) {
      this.clickedData = {}
      this.clickedData.name = data;
      this.clickedData.data = { ...this.cvData };
      this.messageDialog = true;
    },
    handelRateDialog() {
      this.rateDialog = true;
    },
    updateRates(DATA) {
      this.updatedRates = +DATA
    }

  },
};
</script>
  

<style lang="scss" >
#careers_jobs {
  position: relative;

  .number_applicants {
    box-shadow: 0px -2px 1px 0px var(--green2) !important;

    .v-badge__badge {
      border-radius: 8px !important;
      padding: 11px 8px !important;
      height: unset;
    }
  }

  #cv_preview {
    position: relative;
  }

  #cv_preview #fullscreen {
    position: absolute;
    z-index: 55555;
    top: 0.5%;
    left: 74%;
    transform: translate(-52%, 45.2%);
  }

}

// .v-application--is-ltr {
//   #careers_jobs {

//   }
// }
</style>
  