<template>
   <router-view></router-view>
 </template>
 
 
 <script>
 export default {
   name: "PersonalAccount",
   components: {
 
   },
 
   computed: {
 
   },
   watch: {
 
   },
   methods: {
 
   },
 
 }
 </script>
 <style scoped></style>