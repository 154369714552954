<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!openProfileForm" :pageData="pageData" :form="form" :save="save"
            :valid="pageData.isEdit ? valid && !allRemainLoanIsPaid && amountAfterPrevDeducted() : valid && amountAfterPrevDeducted()"
            :deleteMethod="deleteMethod" :actionBtn="viewForm && pageData.isEdit" actionBtnClass="white--text"
            :showDeleted="!allRemainLoanIsPaid" :actionBtnClick="() => openDialog = true"
            :actionBtnValid="!allRemainLoanIsPaid" :actionBtnOutline="true" actionBtnText="reschedule the advance"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit" class="mx-2" :loading="pageData.printLoading.loading"
                  color="primary" depressed height="40">{{ $t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !openProfileForm">
            <template v-slot:summerySlot="{ item }">
               <div v-if="item.key == 'radio'">
                  <v-icon color="blue4">mdi-check</v-icon>
                  {{ item.value }}
               </div>
               <div v-if="item.key == 'loan'">
                  <span class="gray11 pa-4 rounded-lg  d-inline-block">{{ amountAfterPrevDeducted() || '' | tafqeet
                  }}</span>
               </div>
            </template>
         </ControlSummery>
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 mt-5" v-if="viewForm || openProfileForm">
            <v-card class="overflow-hidden transparent py-5 px-1" elevation="0">
               <v-row class="align-center">
                  <GenericInput v-if="!pageData.isEdit" type="date" :value="created_date = $global.GetCurrentDate()"
                     @input="created_date = $event" label="date" :required="true" :disabled="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" v-if="!openProfileForm" :value="form.employee_id"
                     @input="form.employee_id = $event" label="choose the employee" :lookups="employees" selected_prop="id"
                     selected_label="employee_name" :required="true" :isLoading="pageData.editIsLoading"
                     :disabled="pageData.isEdit ? true : false" :cols="[12, 6, 5]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>

                  <GenericInput type="select" :value="form.advance_type" @input="form.advance_type = $event"
                     label="movement type" :lookups="advance_types" selected_prop="id" selected_label="name"
                     :required="true" :isLoading="pageData.editIsLoading"
                     :cols="openProfileForm ? [12, 6, 5] : [12, 6, 4]" :disabled="pageData.isEdit ? true : false">
                  </GenericInput>

                  <GenericInput type="float" :value="Math.abs(form.amount)" @input="form.amount = $event"
                     label="loan amount" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="openProfileForm ? [12, 6, 4] : [12, 6, 5]" :disabled="pageData.isEdit ? true : false">
                  </GenericInput>

                  <GenericInput type="float" :value="Math.abs(form.previously_deducted)"
                     @input="form.previously_deducted = $event" label="previously deducted" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="openProfileForm ? [12, 6, 5] : [12, 6, 4]"
                     :disabled="pageData.isEdit ? true : false">
                  </GenericInput>

                  <GenericInput type="radiogroup" :lookups="radio_loan_type" :required="true" selected_label="name"
                     selected_prop="id" :value="form.loan_type" @input="form.loan_type = $event" label=""
                     :isLoading="false" :cols="[12, 6, 'auto']" :disabled="pageData.isEdit ? true : false" />

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="notes"
                     :required="false" :isLoading="false" :cols="[12, 9, 9]" :disabled="pageData.isEdit ? true : false">
                  </GenericInput>

                  <v-scroll-x-reverse-transition>
                     <v-col cols="12" v-if="form.amount && !amountAfterPrevDeducted()">
                        <v-alert dense outlined type="warning" class="ma-0">
                           <strong>{{ $t("loanMassage") }}</strong>
                        </v-alert>
                     </v-col>
                  </v-scroll-x-reverse-transition>

                  <v-col cols="12" md="6" lg="12" class="py-2">
                     <span class="tafqeet_span gray11 pa-4 rounded-lg my-1">
                        {{ amountAfterPrevDeducted() || '' | tafqeet }}
                     </span>
                  </v-col>

                  <GenericInput type="float" :value="Math.abs(form.monthly_deducted)"
                     @input="form.monthly_deducted = $event" label="monthly deduction" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" :disabled="pageData.isEdit ? true : false">
                  </GenericInput>

                  <v-col cols="6">
                     <span class="rounded-lg d-inline-block">
                        {{ $t('number of installments') }} :{{ handelMonthlyInstallment }}</span>
                  </v-col>

                  <GenericInput type="date" :value="form.starting_loan" @input="form.starting_loan = $event"
                     label="loan start" datePickerType="month" :minDate="$global.GetCurrentDate()" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" :disabled="pageData.isEdit ? true : false">
                  </GenericInput>

                  <GenericInput type="date" :value="start_calculation" @input="start_calculation = $event"
                     label="calculation start" datePickerType="month" :minDate="form.starting_loan" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]" :disabled="pageData.isEdit ? true : false">
                  </GenericInput>

                  <GenericInput type="date" :value="end_calculation" @input="end_calculation = $event"
                     label="calculation end" datePickerType="month" :minDate="form.starting_loan" :disabled="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- Form -->

         <RecalculateLoanDialog v-if="viewForm" :openDialog="openDialog" :closeDialog="() => openDialog = false"
            :remainLoanAmount="amountAfterPrevDeducted()" :updateRemainLoan="updateRemainLoan" />

         <EmployeesLoanTransactions v-show="viewForm && pageData.isEdit" :loansTransactionDetails="loansTransactionDetails"
            :updateRemainLoan="updateRemainLoan" />

         <LoanTransactionPrint :printAction="() => print = false" :print="print" :printData="printData"
            :printStaticData="printStaticData" />
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import RecalculateLoanDialog from "./RecalculateLoanDialog.vue";
import EmployeesLoanTransactions from "./EmployeesLoanTransactions.vue";
import LoanTransactionPrint from "./LoanTransactionPrint.vue";

export default {
   name: "LoanControl",

   data: () => ({
      pageData: {
         screen_code: "09-016",
         url: null,
         controlRoute: "/transactions/loan_transaction-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      start_calculation: '',
      end_calculation: '',
      radio_loan_type: [],
      radio_loan_name: "",
      viewForm: false,
      summery: [],
      valid: false,
      employees: [],
      tab: 0,
      sales_areas: [],
      sales_agents: [],
      account_trees: [],
      currencies: [],
      countries: [],
      cities: [],
      purchases_vendor_types: [],
      openDialog: false,
      attachmentDialog: false,
      menuStartDate: false,
      menuDate: false,
      employee_profile_id: "",
      loansTransactionDetails: [],
      advance_types: [],
      created_date: null,
      form: {
         employee_id: null,
         previously_deducted: 0,
         monthly_deducted: null,
         amount: null,
         installments_count: null,
         starting_loan: null,
         start_date: null,
         end_date: null,
         loan_type: 2,
         advance_type: 1,
         notes: null,
      },
      rules: {
         required: value => !!value || 'Required.',
      },
      printData: [],
      printStaticData: Object,
      print: false,
      printResponse: {},
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      RecalculateLoanDialog,
      LoanTransactionPrint,
      EmployeesLoanTransactions
   },
   computed: {
      handelMonthlyInstallment() {
         return Math.ceil(this.form.monthly_deducted ? (this.amountAfterPrevDeducted() / this.form.monthly_deducted) : "")
      },
      saveEmployeeDataControl() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid, this.handelMonthlyInstallment, this.start_calculation, this.end_calculation)
         } else {
            return false
         }
      },
      remainLoanAmount() {
         return this.form.amount - this.loansTransactionDetails.filter((loan) => loan.is_paid == 1).reduce((prev, current) => prev + current.amount, 0)
      },
      allRemainLoanIsPaid() {
         return this.loansTransactionDetails.every((loan) => loan.is_paid == 1)
      },
      amountAfterPrevDeducted() {
         return () => {
            if (this.form.previously_deducted < this.form.amount) {
               return this.form.amount - this.form.previously_deducted
            } else if (this.form.previously_deducted >= this.form.amount) return false
         }
      },

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`advance_action/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.printResponse = response.data;
                     this.loansTransactionDetails = response.data.details
                     this.employees = response.data.employees;
                     this.advance_types = response.data.advance_types;
                     !this.openProfileForm ? this.radio_loan_name = this.$global.FilterArrayOfObjectElement(this.radio_loan_type, "id", response.data.data.loan_type, "name") : ""
                     !this.openProfileForm ? this.form = response.data.data : ""
                     this.start_calculation = this.form.start_date
                     this.end_calculation = this.form.end_date
                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }
                     !this.openProfileForm ? this.summery = [
                        {
                           label: 'date',
                           value: response.data.data.add_date || '-',
                           class: '',
                           cols: null, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'employee',
                           value: response.data.data.employee_name || '-',
                           class: '',
                           cols: null, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'loan amount',
                           value: response.data.data.amount || 0,
                           type: 'float',
                           class: '',
                           cols: 12, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'previously deducted',
                           value: response.data.data.previously_deducted || 0,
                           type: 'float',
                           class: '',
                           cols: 12, sm: null, md: 3, lg: 3, xl: 3,
                        },
                        {
                           label: 'radio_checked',
                           value: this.radio_loan_name || '-',
                           type: 'slot',
                           key: "radio",
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        },
                        {
                           label: 'loan mount',
                           value: response.data.data.amount || '-',
                           type: 'slot',
                           key: "loan",
                           class: '',
                           cols: 12, sm: null, md: 12, lg: 12, xl: 12,
                        },
                        {
                           label: 'monthly deduction',
                           value: response.data.data.monthly_deducted || 0,
                           type: 'float',
                           class: '',
                           cols: 12, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'number of installments',
                           value: response.data.data.installments_count || '-',
                           class: '',
                           cols: 12, sm: null, md: 5, lg: 5, xl: 5,
                        },
                        {
                           label: 'loan start',
                           value: response.data.data.starting_loan || '-',
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        },
                        {
                           label: 'calculation start',
                           value: response.data.data.start_date || '-',
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        },
                        {
                           label: 'calculation end',
                           value: response.data.data.end_date || '-',
                           class: '',
                           cols: 12, sm: null, md: 4, lg: 4, xl: 4,
                        }


                     ] : ""

                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }

               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`advance_action/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.advance_types = response.data.advance_types;
                     this.employees = response.data.employees;

                  }
               })
            } else {
               this.$router.push('/')
            }

         }
         this.radio_loan_type = [
            { id: 1, name: this.$i18n.t('create a payment voucher') },
            { id: 2, name: this.$i18n.t('deducted from salary') }
         ]

      },
      save() {
         this.form.installments_count = this.handelMonthlyInstallment;
         this.form.start_date = this.start_calculation;
         this.form.end_date = this.end_calculation;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`advance_action/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`advance_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      save_employee() {
         this.form.installments_count = this.handelMonthlyInstallment;
         this.form.start_date = this.start_calculation;
         this.form.end_date = this.end_calculation;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.$api.POST_METHOD(`advance_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     // this.saveData = false;
                     this.closeProfileForm()
                     this.refreshTable()
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`advance_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  // this.saveData = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`advance_action/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      GetYearMonth(param) {
         var d = new Date(param),
            year = d.getFullYear(),
            month = '' + (d.getMonth() + 1);

         return year + '-' + month;
      },
      updateRemainLoan(amount, updateMonthlyDeductedAndStartLoan) {
         console.log('updateMonthlyDeductedAndStartLoan', updateMonthlyDeductedAndStartLoan);
         if (updateMonthlyDeductedAndStartLoan !== false) {
            this.form.monthly_deducted = updateMonthlyDeductedAndStartLoan.monthlyDeduction
            this.form.starting_loan = updateMonthlyDeductedAndStartLoan.start_date
            setTimeout(() => this.save(), 1200)
         }
         this.loansTransactionDetails = amount;
      },
      printMethod() {
         console.log(this.printResponse.details);
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = this.printResponse.data;
         this.printStaticData.master.amountAfterPrevDeducted = this.amountAfterPrevDeducted();
         this.printStaticData.details = this.printResponse.details;
         console.log(this.printStaticData.details);
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },
   },
   watch: {
      'form.starting_loan'() {
         return this.start_calculation = this.form.starting_loan ? this.form.starting_loan : ''
      },
      start_calculation() {
         this.end_calculation = this.start_calculation ? this.GetYearMonth(this.$global.AddToDate(this.start_calculation, this.handelMonthlyInstallment * 30)) : ""
      },
      handelMonthlyInstallment() {
         this.end_calculation = this.form.starting_loan ? this.GetYearMonth(this.$global.AddToDate(this.start_calculation, this.handelMonthlyInstallment * 30)) : ""
      },
   },
   updated() {
      this.saveEmployeeDataControl
   },
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function },
   }

};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}

.tafqeet_span {
   max-width: fit-content !important;
   display: block !important;
}
</style>
