<template>
    <v-dialog v-model="openDialog" persistent width="575">
        <v-card class="pa-5" rounded="rounded" min-height="450">

            <v-form ref="form" v-model="valid">
                <v-card-title class="text-h5 gray2--text px-0 font-weight-bold">
                    <div>{{ $t('evaluation') }}</div>
                    <v-spacer></v-spacer>

                    <v-icon large :color="!ratesIsChanged ? 'green1' : 'red5'" @click="save">
                        {{ !ratesIsChanged ? 'mdi mdi-check-circle' : 'mdi mdi-close-circle' }}
                    </v-icon>
                </v-card-title>

                <v-row align="center" justify="center" v-if="loading" style="height: 40vh;">
                    <v-col cols="">
                        <AnimatedLoading :height="100" :hideText="true" :isLoading="loading" />
                    </v-col>
                </v-row>

                <v-row v-if="!loading">
                    <v-col cols="12">
                        <v-card class="d-flex align-center py-3 px-2" color="gray12" elevation="0">
                            <span class="gray2--text font-weight-bold">{{ $t('overall assessment') }}</span>
                            <v-spacer></v-spacer>
                            <v-rating half-increments v-model="calcValue" readonly color="yellow darken-3"
                                background-color="yellow darken-3" size="25"></v-rating>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12">
                        <v-card class="d-flex align-center" elevation="0">
                            <span class="gray2--text font-weight-bold">{{ $t('outside look') }}</span>
                            <v-spacer></v-spacer>
                            <v-rating half-increments v-model="form.outside_look" color="yellow darken-3"
                                background-color="yellow darken-3" size="25"></v-rating>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="d-flex align-center" elevation="0">
                            <span class="gray2--text font-weight-bold">{{ $t('Personal') }}</span>
                            <v-spacer></v-spacer>
                            <v-rating half-increments v-model="form.personal" color="yellow darken-3"
                                background-color="yellow darken-3" size="25"></v-rating>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="d-flex align-center" elevation="0">
                            <span class="gray2--text font-weight-bold">{{ $t('Evaluate the HR') }}</span>
                            <v-spacer></v-spacer>
                            <v-rating half-increments v-model="form.hr_rate" color="yellow darken-3"
                                background-color="yellow darken-3" size="25"></v-rating>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="d-flex align-center" elevation="0">
                            <span class="gray2--text font-weight-bold">{{ $t('Technical level') }}</span>
                            <v-spacer></v-spacer>
                            <v-rating half-increments v-model="form.tech_rate" color="yellow darken-3"
                                background-color="yellow darken-3" size="25"></v-rating>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue'

export default {
    name: "RateDialog",
    props: {
        openDialog: { default: false },
        rates: { default: Object },
        closeDialog: { type: Function },
        updateRates: { type: Function },
    },
    data: () => ({
        valid: false,
        loading: false,
        form: {
            outside_look: 0,
            personal: 0,
            hr_rate: 0,
            tech_rate: 0,
        },
        oldForm: {}
    }),
    methods: {
        save() {
            if (!this.ratesIsChanged) {
                this.loading = true;
                // this.form._method = 'PUT';
                this.$api.POST_METHOD(`rate_applicant/${this.rates.data.id}`, this.form, false).then((response) => {
                    this.loading = false;
                    if (response.check) {
                        console.log("response", response);
                        this.oldForm = { ...this.form }
                        this.updateRates(this.calcValue);
                        this.closeDialog();
                    }
                });
            } else {
                this.closeDialog();
            }

        },
    },
    watch: {
        openDialog() {
            if (this.openDialog) {
                this.rates.data.rates && (this.form = this.rates.data.rates)
                this.oldForm = { ...this.form }
                console.log('this.rates', this.rates.data.rates);
            }
        },

    },
    computed: {
        calcValue() {
            return ((this.form?.outside_look + this.form?.personal + this.form?.hr_rate + this.form?.tech_rate) / 4).toFixed(1);
        },
        ratesIsChanged() {
            return Object.keys(this.oldForm).every(oldForm => this.oldForm[oldForm] == this.form[oldForm])
        }
    },
    components: { AnimatedLoading }
}


</script>

<style lang="scss" scoped>
.v-sheet.v-card {
    border-radius: unset;
}
</style>
