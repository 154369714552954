<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader v-if="!employeeId" :pageData="pageData" :getData="getData" :getSearch="getSearch">
      </EntityHeader>
      <!-- End Of Header -->

      <!-- employee header -->
      <v-row>
        <v-col cols="12" sm="12" v-if="employeeId">
          <div class="d-flex justify-space-between align-center">
            <span class="font-weight-bold text-h6 ma-0">
              {{ !showDynamicTable ? $t("loan movement") : `${$t("advance payment")}: ${rowID}` }}</span>
            <v-btn depressed :min-width="90" @click="openForm = true" class="my-2 rounded-lg" color="primary" :height="37"
              v-if="!openForm" v-show="!showDynamicTable">
              <v-icon left>mdi-plus</v-icon>
              {{ $t("add new loan") }}
            </v-btn>
            <!-- back for transactions loan table -->
            <v-btn depressed @click="showDynamicTable = false; getEmployeeData();" :min-width="90"
              :loading="pageData.isLoading" text class="my-2 mx-2 rounded-lg white--text" :height="40" color="red"
              v-show="showDynamicTable">
              {{ $t('back') }}</v-btn>

            <div v-if="openForm">
              <v-btn depressed @click="openForm = false" :min-width="90" :loading="pageData.isLoading" text
                class="my-2 mx-2 rounded-lg white--text" :height="40" color="red">
                {{ $t('back') }}</v-btn>
              <v-btn depressed @click="save_employee" :disabled="!valid" :min-width="90" :loading="loading"
                class="my-2 mx-2 rounded-lg white--text" :height="40" color="success">
                {{ $t('create') }}</v-btn>
            </div>
          </div>
        </v-col>

      </v-row>
      <LoanControl v-if="openForm" :openProfileForm="openForm" :saveEmployeeData="saveEmployeeData" />
      <EmployeesLoanTransactions v-if="showDynamicTable && employeeId" :rowID="rowID" :callGetData="callGetData" />
      <!-- end employee header -->
      <v-row v-if="!openForm && !showDynamicTable">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" 
            :rowClicked="(row) => employeeId ? showTransactions(row) : ''" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod"
             :printValueMethod="printMethod">
            <template v-slot:th="{ header }">
              {{ $t(header.text) }}
            </template>
            <template v-slot:td="{ row ,header}">
              <div v-if="header.key == 'employee_name'">
                <a @click="navigationToProfile(row)" class="text-decoration-underline">
                  {{ row.employee_name }}
                </a>
              </div>
            </template>
            <template v-slot:afterTable>
              <v-row v-if="!pageData.isLoading && pageData.rows.length && employeeId" justify="space-between" class="font-weight-bold text-center text-body-2 mt-2">
                <v-col cols="4">{{ $t('total number of loans') }}</v-col>
                <v-col cols="2">{{ advanceData_length | float }}</v-col>
                <v-col cols="4">{{ $t('total amount borrowed') }}</v-col>
                <v-col cols="2">{{ total_advance_amount | float }}</v-col>
              </v-row>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && !openForm && !showDynamicTable && pageData.rows.length > 0">
        <v-col cols="12" sm="12" v-if="!employeeId">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>

    <LoanTransactionPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import LoanControl from "./LoanControl.vue";
import EmployeesLoanTransactions from "../../Transactions/Loan/EmployeesLoanTransactions.vue";
import LoanTransactionPrint from "./LoanTransactionPrint.vue";
export default {
  name: "LoanEntity",

  data: () => ({
    pageData: {
      screen_code: "09-016",
      url: null,
      controlRoute: "transactions/loan_transaction-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    showDynamicTable: false,
    openForm: false,
    valid: false,
    loading: false,
    form: {},
    advanceData_length: 0,
    total_advance_amount: 0,
    rowID: null,
    printData: [],
    printStaticData: Object,
    print: false,
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    LoanControl,
    EmployeesLoanTransactions,
    LoanTransactionPrint
},
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.employeeId ? this.getEmployeeData() : this.getData();
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.employeeId ? this.getEmployeeData() : this.getData();
    },
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      !this.employeeId ? this.pageData.tableHeader = [
        { text: "order number", key: "id", type: 'text', classes: "" },
        { text: "date", key: "add_date", type: 'date', classes: "" },
        { text: "employee name", key: "employee_name", type: 'slot', classes: "" },
        { text: "monthly installment", key: "monthly_deducted", type: 'float', classes: "" },
        { text: "advance value", key: "amount", type: 'float', classes: "" },
        { text: "number of installments", key: "installments_count", type: 'text', classes: "" },
        { text: "paid up", key: "previously_deducted", type: 'float', classes: "" },
        { text: "calculation start", key: "start_date", type: 'text', classes: "" },
        { text: "due date", key: "starting_loan", type: 'text', classes: "" },
        { text: "movement type", key: "advance_type_name", type: 'text', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ] :
        this.pageData.tableHeader = [
          { text: "order number", key: "id", type: 'text', classes: "cursor_pointer" },
          { text: "number of installments paid", key: "paid_count", type: 'text', classes: "cursor_pointer" },
          { text: "number of installments", key: "installments_count", type: 'text', classes: "cursor_pointer" },
          { text: "advance value", key: "amount", type: 'non_zero_float', classes: "cursor_pointer" },
          { text: "Amount paid", key: "paid_amount", type: 'non_zero_float', classes: "cursor_pointer" },
          { text: "Remaining amount", key: "unpaid_amount", type: 'non_zero_float', classes: "cursor_pointer" },
          { text: "calculation start", key: "start_date", type: 'text', classes: "cursor_pointer" },
          { text: "movement type", key: "advance_type_name", type: 'text', classes: "" },
          { text: "due date", key: "starting_loan", type: 'text', classes: "cursor_pointer" },
        ]


      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`advance_action${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getEmployeeData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`get_employee_advance/${this.employeeId}${this.pageData.queryParam}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.rows = response.data;
            this.advanceData_length = response.data.length;
            this.total_advance_amount = response.data.reduce((prev, current) => prev + +current.amount, 0);

          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`advance_action/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`advance_action_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/purchases_vendor/${row.id}`)
    },
    showTransactions(row) {
      console.log("yes", row);
      this.rowID = row.id
      this.showDynamicTable = true
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    // collect data and send on save employee page
    saveEmployeeData(form, valid, monthlyInstallment, start_calculation, end_calculation) {
      this.form = form
      this.form.installments_count = monthlyInstallment;
      this.valid = valid;
      this.form.start_date = start_calculation;
      this.form.end_date = end_calculation;
    },
    save_employee() {
      if (this.valid) {
        this.loading = true;
        this.form.employee_id = +this.employeeId
        this.$api.POST_METHOD(`advance_action`, this.form).then((response) => {
          this.loading = false;
          if (response.check) {
            this.openForm = false
            this.getEmployeeData()
            this.callGetData()
          }
        })
      }
    },
    navigationToProfile(row) {
      this.$router.push(`/codes/employee-control/${row.employee_id}`)
    },
    printMethod(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api
        .GET_METHOD(`advance_action/${row.id}`).then((response) => {
          this.pageData.printLoading.loading = false;
          if (response.check) {
            this.printData = [response.data.data];
            this.printStaticData.master = response.data.data;
            this.printStaticData.details = response.data.details;
            this.print = true;
            setTimeout(() => {
              this.printData = [];
              this.printStaticData = Object;
              this.print = false;
              this.pageData.printLoading.loading = false;
            }, 1000);
          }
        });
    },
  },
  props: {
    callGetData: { type: Function },
    employeeId: { type: String }
  }
};
</script>
