<template>
    <v-dialog v-model="openDialog" @click:outside="closeDialog" width='auto' :retain-focus="false">
        <v-card>
            <div class="pa-4 ">
                <!-- title -->
                <p class="mb-5"> {{ $t("Termination of the employee's contract") }} - {{ employees[0].employee_name }}</p>

                <!-- date input -->
                <GenericInput type="date" :maxDate="''"  :value="termination_contract" @input="termination_contract = $event"
                    label="date of termination of contract" :required="false" :hide-details="true" :isLoading="isLoading"
                    :cols="[12, 12, 12]" :disabled="true">
                </GenericInput>

                <!-- button Actions -->
                <div class="d-flex justify-space-between mt-5">
                    <v-btn class="error--text" depressed @click="closeDialog" :loading="saveDataLoading">
                        {{ $t("no") }}
                    </v-btn>
                    <v-btn @click="save" color="secondary" class="white--text" depressed :loading="saveDataLoading">
                        {{ $t("yes") }}
                    </v-btn>

                </div>
            </div>
         </v-card>
    </v-dialog>
</template>

<script>

export default {

    name: "TerminationContractDialog",

    data: () => ({
        termination_contract: null,
        saveDataLoading: false
    }),

    watch: {},

    mounted() { 
        this.termination_contract =  this.$global.GetCurrentDate()
    },

    methods: {
        save() {
            this.saveDataLoading = true
            this.$api.POST_METHOD(`contract_termination/${this.$route.params.id}`, this.termination_contract).then((response) => {
                this.saveDataLoading = false
                if (response.check) {
                     this.closeDialog();
                    this.$router.push('/codes/contract')
                }
            })
        }
    },
    computed: {},

    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        employees: { type: Array },
    },

};
</script>

<style lang="scss" scoped></style>