<template>
   <section>
      <v-container fluid class="pa-5">
         <v-row justify="end">
            <v-btn depressed @click="exitDialog = true"  class="my-2 mx-2 rounded-lg" text plain :height="40" color="error">
               {{ $t('cancel') }}
            </v-btn>
            <v-btn depressed @click="save" :loading="pageData.isLoading" :min-width="148" class="my-2 mx-2 rounded-lg white--text" :height="40"
               color="success">
               {{ $t('save') }}</v-btn>
         </v-row>
         <v-col cols="12" class="text-caption">{{ $t('financial year figures') }}</v-col>
         <!-- Form -->
         <v-form ref="form" v-model="valid">
            <v-card class="transparent py-5 px-1" elevation="0">
               <v-row align="center">
                  <GenericInput type="date" :value="form.finance_year_from" @input="form.finance_year_from = $event"
                     label="beginning of the year" :required="true" :isLoading="pageData.isLoading" :cols="[12, 4, 4]">
                  </GenericInput>
                  <GenericInput type="date" :value="form.finance_year_to" @input="form.finance_year_to = $event"
                     label="end of the year" :required="true" :isLoading="pageData.isLoading" :cols="[12, 4, 4]">
                  </GenericInput>
                  <GenericInput type="select" :value="form.finance_year_calendar_type"
                     @input="form.finance_year_calendar_type = $event" icon="mdi-chevron-down" selected_prop="label"
                     label="type of calendar" :lookups="calendar" selected_label="name" :required="true"
                     :isLoading="pageData.isLoading" :cols="[12, 4, 4]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
      <ExitConfirmationModal :openDialog="exitDialog" route="true" :closeDialog="() => exitDialog = false" />
   </section>
</template>



<script>
import ExitConfirmationModal from '@/components/modals/ExitConfirmationModal.vue';

export default {
   name: "FinancialYearControl",

   data: () => ({
      pageData: {
         screen_code: "11-001",
         url: null,
         controlRoute: "settings_hr/financial_year-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      employees: [],
      vacations: [],
      employee_data: [],
      calendar: [],
      openDialog: false,
      attachmentDialog: false,
      vacation_summary_name: "",
      employee_profile_id: "",
      form: {
         finance_year_from: null,
         finance_year_to: null,
         finance_year_calendar_type: null,
      },
      exitDialog: false
   }),
   components: {
      ExitConfirmationModal
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
         this.calendar = [
            {
               id: 1,
               name: 'الهجري',
               label: 'hijri',
            },
            {
               id: 2,
               name: 'الميلادي',
               label: 'ad',
            }
         ]
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`finance_year/${this.$route.params.id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;
                     console.log("response", response);
                     this.form = response.data.data
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`finance_year/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                  }
               })
            } else {
               this.$router.push('/')
            }

         }
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`finance_year/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`finance_year`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
   }
};
</script>


