<template>
    <v-dialog v-model="openDialog" persistent width="95%">
        <v-card class="pa-5" rounded="rounded">
            <v-form ref="form" v-model="valid">
                <v-card-title class="text-h6 px-0 font-weight-bold">
                    <div>{{ $t('Filter') }}</div>
                    <v-spacer></v-spacer>
                    <v-icon large color="red5" @click="closeDialog" class="cursor_pointer">mdi mdi-close-box</v-icon>
                </v-card-title>

                <v-row>
                    <!-- Personal details -->
                    <v-col cols="12" md="4">
                        <v-card color="gray12" elevation="0" class="pa-4">
                            <h4>{{ $t('personal details') }}</h4>
                            <v-row class="mt-4">
                                <!-- type -->
                                <label class="px-3 gray5--text font-weight-bold"> {{ $t('type') }}</label>
                                <v-col cols="12">
                                    <v-radio-group row v-model="form.gender" hide-details>
                                        <v-radio v-for="gen in gender" :key="gen.id" :label="gen.name"
                                            :value="gen.id"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12"><v-divider></v-divider></v-col>

                                <!-- age -->
                                <label class="px-3 gray5--text font-weight-bold"> {{ $t('the age') }}</label>
                                <v-col cols="12">
                                    <v-range-slider hide-details v-model="form.age" :tick-labels="ages" color="info" min="1" step="1"
                                        max="8" ticks="always" tick-size="4">
                                        <template v-slot:thumb-label="props">
                                            {{ $t(ages[props.value - 1]) }}
                                        </template>
                                    </v-range-slider>
                                </v-col>
                                <v-col cols="12"><v-divider></v-divider></v-col>

                                <!-- Universities -->
                                <GenericInput type="select" :value="form.university" @input="form.university = $event"
                                    label="Universities/institutes" :lookups="mainData.universities" selected_label="name"
                                    :required="false" :multi="true" selected_prop="id" :isLoading="false"
                                    :cols="[12, 12, 12]" />
                                <v-col cols="12"><v-divider></v-divider></v-col>

                                <!-- Colleges -->
                                <GenericInput type="select" :value="form.college" @input="form.college = $event"
                                    label="colleges" :lookups="mainData.colleges" selected_label="name" :required="false"
                                    :multi="true" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]" />

                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- Personal details -->

                    <!-- Professional details -->
                    <v-col cols="12" md="4">
                        <v-card color="gray12" elevation="0" class="pa-4">
                            <h4>{{ $t('Professional details') }}</h4>
                            <v-row class="mt-4">
                                <GenericInput type="number" :value="form.experience_years"
                                    @input="form.experience_years = $event" label="Years of experience" :required="false"
                                    :isLoading="false" :cols="[12, 12, 12]" />
                                <v-col cols="12"><v-divider></v-divider></v-col>

                                <!-- Expected Salary -->
                                <label class="px-3 gray5--text font-weight-bold"> {{ $t('Expected Salary') }}</label>
                                <v-col cols="12">
                                    <v-range-slider hide-details v-model="form.expected_salary" :tick-labels="expected_salary"
                                        color="info" min="1" step="1" max="10" ticks="always" tick-size="4">
                                        <template v-slot:thumb-label="props">
                                            <span>{{ expected_salary[props.value - 1] }}</span>
                                            <span v-if="expected_salary[props.value - 1] !== $t('all')">K</span>
                                        </template>
                                    </v-range-slider>
                                </v-col>
                                <v-col cols="12"><v-divider></v-divider></v-col>

                                <!-- Current salary -->
                                <label class="px-3 gray5--text font-weight-bold"> {{ $t('Current salary') }}</label>
                                <v-col cols="12">
                                    <v-range-slider hide-details v-model="form.current_salary" :tick-labels="current_salary" color="info"
                                        min="1" step="1" max="10" ticks="always" tick-size="4">
                                        <template v-slot:thumb-label="props">
                                            <span>{{ current_salary[props.value - 1] }}</span>
                                            <span v-if="current_salary[props.value - 1] !== $t('all')">K</span>
                                        </template>
                                    </v-range-slider>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- Professional details -->

                    <!-- status -->
                    <v-col cols="12" md="4">
                        <v-card color="gray12" elevation="0" class="pa-4">
                            <h4>{{ $t('status') }}</h4>
                            <v-row class="mt-4">
                                <v-col cols="12" class="d-flex align-center">
                                    <label class="gray5--text font-weight-bold"> {{ $t('overall assessment') }}</label>
                                    <v-spacer></v-spacer>
                                    <v-rating v-model="form.overall_rate" color="yellow darken-3"
                                        background-color="yellow darken-3" size="25"></v-rating>
                                </v-col>
                                <v-col cols="12"><v-divider></v-divider></v-col>

                                <!-- Acceptance stages -->
                                <label class="px-3 gray5--text font-weight-bold"> {{ $t('Acceptance stages') }}</label>
                                <v-col cols="12">
                                    <v-btn v-for="acceptance in acceptance_stages" :key="acceptance.id"
                                        @click="form.status = acceptance.id" depressed
                                        :class="form.status == acceptance.id ? 'white--text ma-1 blue5' : 'gray2--text ma-1'"
                                        min-width="29" height="30" :loading="loading">
                                        {{ $t(acceptance.name) }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12"><v-divider></v-divider></v-col>

                                <!-- They were seen -->
                                <label class="px-3 gray5--text font-weight-bold"> {{ $t('They were seen') }}</label>
                                <v-col cols="12">
                                    <v-radio-group row v-model="form.is_read" hide-details>
                                        <v-radio v-for="read in is_read" :key="read.id" :label="read.name"
                                            :value="read.id"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- status -->

                    <v-card-actions style="width: 100%;" class="mt-3 justify-end">
                        <v-btn @click="clearFilter" :disabled="!valid" depressed color="blue5" class="white--text"
                            min-width="192" height="50" :loading="loading">
                            <v-icon class="mx-1">mdi mdi-reload</v-icon>
                            {{ $t('Reset default') }}
                        </v-btn>

                        <v-btn :loading="loading" depressed @click="saveFilter" color="grey11" class="font-weight-bold"
                            min-width="120" height="50">
                            <v-icon class="mx-1">mdi mdi-filter-outline</v-icon>
                            {{ $t('Filter') }}
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "FilterDialog",
    props: {
        openDialog: { default: false },
        closeDialog: { default: Function },
        getData: { default: Function },
        mainData: { default: Object },
        queryParam: { default: Object },
    },
    data: () => ({
        form: {
            status: '',
            career_id: '',
            college: '',
            university: '',
            gender: '',
            experience_years: '',
            overall_rate: '',
            is_read: '',
            age: [0, 0],
            expected_salary: [0, 0],
            current_salary: [0, 0],
        },
        is_apply_count: 1,
        valid: false,
        loading: false,
        radioGroup: 1,
        gender: [],
        universities: [],
        colleges: [],
        years_of_experience: [],
        ages: [],
        expected_salary: [],
        current_salary: [],
        acceptance_stages: [],
        is_read: [],
        expected_salary_range: [1, 9],
        current_salary_range: [1, 9],
        rating: 0,
        translateAll: '',
        seasons: [
            'Winter',
            'Spring',
            'Summer',
            'Fall',
        ],
        icons: [
            'mdi-snowflake',
            'mdi-leaf',
            'mdi-fire',
            'mdi-water',
        ],
    }),

    methods: {
        season(val) {
            return this.icons[val]
        },
        saveFilter() {
            this.calcRang(this.form.age, this.ages)
            this.calcRang(this.form.expected_salary, this.expected_salary)
            this.calcRang(this.form.current_salary, this.current_salary)

            Object.keys(this.form).forEach(KEY => {
                if (this.form[KEY] && (this.form[KEY][1] !== 1)) {
                    this.queryParam.set(KEY, this.form[KEY])
                }

            })
            this.getData()
            this.closeDialog()
        },
        clearFilter() {
            this.form = {
                status: '',
                career_id: '',
                college: '',
                university: '',
                gender: '',
                experience_years: '',
                overall_rate: '',
                is_read: '',
                age: [0, 0],
                expected_salary: [0, 0],
                current_salary: [0, 0],
            }
            Object.keys(this.form).forEach(KEY => this.queryParam.delete(String(KEY)))
            this.getData()
            this.closeDialog()
        },
        calcRang(FORM, ARRAY) {
            FORM[0] = ARRAY[FORM[0] - 1]
            FORM[1] = ARRAY[FORM[1] - 1] == this.translateAll ? ARRAY[FORM[1] - 2] : ARRAY[FORM[1] - 1]
        }
    },
    computed: {},
    watch: {
        openDialog() {
            // if (this.openDialog) {

            // }
        }
    },
    mounted() {
        this.gender = [
            {
                id: 3,
                name: this.$i18n.t('all')
            },
            {
                id: 1,
                name: this.$i18n.t('male')
            },
            {
                id: 2,
                name: this.$i18n.t('female')
            },

        ]
        this.is_read = [
            {
                id: 1,
                name: this.$i18n.t('all')
            },
            {
                id: 2,
                name: this.$i18n.t('They were seen')
            },
            {
                id: 3,
                name: this.$i18n.t('They were not seen')
            },
        ]
        this.acceptance_stages = [
            {
                id: null,
                name: this.$i18n.t('all')
            },
            {
                id: 1,
                name: this.$i18n.t('interview')

            },
            {
                id: 2,
                name: this.$i18n.t('suspense')
            },
            {
                id: 3,
                name: this.$i18n.t('Acceptance')
            },
            {
                id: 4,
                name: this.$i18n.t('reject')
            },
        ]
        this.translateAll = this.$i18n.t('all')
        this.ages = [1, 22, 25, 30, 35, 40, 45, this.translateAll];
        this.expected_salary = [1, 2, 3, 4, 5, 6, 7, 8, 9, this.translateAll]
        this.current_salary = [1, 2, 3, 4, 5, 6, 7, 8, 9, this.translateAll]
    }
}


</script>

<style lang="scss" scoped>
#expected_salary,
#ages {
    span {
        padding: 0 15px;
    }

    span:last-child {
        padding: unset;
    }

    span:first-child {
        padding: unset;
    }
}

#current_salary {
    span {
        padding: 0 0.6dvw;
    }

    span:last-child {
        padding: unset;
    }

    span:first-child {
        padding: unset;
    }
}

#expected_salary {
    span {
        padding-right: 5%;
        padding-left: 0;
    }

}
</style>
