<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :showSearch="false">
        <template v-slot:rightSide>
          <GenericInput type="select" :lookups="selectData" :value="selectData_id" icon="mdi-chevron-down"
            @input="selectData_id = $event" label="" :multi="false" selected_label="name" selected_prop="id"
            :isLoading="false" :cols="[2, 2, 5]">
          </GenericInput>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :pageData="pageData" :restoreValueMethod="restoreMethod">
            <template v-slot:td="{ row, header }">
              <!-- status -->
              <div class="actions_two d-flex text-uppercase" v-if="header.key === 'status'">
                <v-chip small :class="handelStatus(row).style" text elevation="0">
                  {{ $t(handelStatus(row).name) }}</v-chip>
              </div>

              <!-- <div v-if="header.text === 'salary calculation'">
                {{ $global.MonthYearArFormat(row.accounting_month) }}
              </div> -->
            </template>

            <template v-slot:actions="{ row }">

              <!-- Show -->
              <!-- <span>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="handelShowDialog(row)" v-bind="attrs" v-on="on" color="primary"
                      class="cursor_pointer mx-1">mdi-eye-outline</v-icon>
                  </template>
                  <span>{{ $t('show') }}</span>
                </v-tooltip>
              </span> -->


              <!-- Profile -->
              <span>
                <v-menu offset-y open-on-hover transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="gray"
                      class="cursor_pointer mx-1">mdi-account-circle-outline</v-icon>
                  </template>
                  <v-list min-width="220">
                    <v-list-item class="flex-wrap text-center">
                      <v-list-item-title class="text-start text-capitalize">
                        <div>{{ $t("done by") }}: {{ row.username }}</div>
                        <div class="mt-3">{{ $t("job title") }}: {{ row.title_name }}</div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>

              <!-- Rejected Reason -->
              <span v-if="row.status == 3">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="primary" @click="(openDialog = true, rejectMass = row)"
                      class="cursor_pointer mx-1">mdi-alert-circle-outline</v-icon>
                  </template>
                  <span>{{ $t('Rejected Reason') }}</span>
                </v-tooltip>
              </span>

              <!-- edit -->
              <span v-if="row.status == 1 && pageData.isTrashed !== 0">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="edit(row)" v-bind="attrs" v-on="on" width="30" height="30" icon fab>
                      <img class="mx-2 cursor_pointer" style="height: 16px;" src="@/assets/img/svg_icons/pen.svg"
                        alt="" />
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </span>

              <!-- delete -->
              <span v-if="row.status == 1 && pageData.isTrashed !== 0">
                <v-tooltip bottom color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="dialogDeleteItem = row, deleteDialog = true" v-bind="attrs" v-on="on" width="30"
                      height="30" icon fab>
                      <img class="mx-2 cursor_pointer" style="height: 16px;" src="@/assets/img/svg_icons/delete_icon.svg"
                        alt="" />
                    </v-btn>
                  </template>
                  <span>{{ $t('delete') }}</span>
                </v-tooltip>
              </span>

              <!-- print -->
              <v-btn icon elevation="0" :loading="row.printLoading" @click="tableDataPrint(row)">
                <ExportTableBtn :groupHeader="printGroupHeader" :showGroupHeader="true" :footerData="[]"
                  :header="pageData.printTableHeader" :exportData="pageData.printRows" printOrientation="landscape"
                  :printPreviewOpenFun="printPreviewOpenFun">
                </ExportTableBtn>
              </v-btn>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>

      <!-- Pagination -->
      <v-row v-if="!pageData.isLoading && pageData.rows.length">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>

      <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog"
        :item="dialogDeleteItem.name ? dialogDeleteItem.name : null" :backValueMethod="deleteMethod">
      </DeleteConfirmation>

      <!-- Rejected Reason Dialog -->
      <SalaryCalculationDialog :openDialog="openDialog" :rejectMass="rejectMass"
        :closeDialog="() => openDialog = false" />

      <!-- Show Employee Dialog to View and Print -->
      <SalaryCalculationShowDialog :openDialog="showDialog" :closeDialog="() => showDialog = false" :data="rowData" />

      <SalaryCalculationPrint :printAction="() => print = false" :print="print" :printData="printData"
        :printStaticData="printStaticData" />
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
import SalaryCalculationDialog from "./SalaryCalculationDialog.vue";
import SalaryCalculationShowDialog from "./SalaryCalculationShowDialog.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import SalaryCalculationPrint from './SalaryCalculationPrint.vue';
import ExportTableBtn from "@/components/ui/ExportTableBtn.vue";
export default {
  name: "SalaryCalculationEntity",

  data: () => ({
    pageData: {
      screen_code: "09-019",
      url: null,
      controlRoute: "transactions/salary_calculator_transaction-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      printRows: [],
      printTableHeader: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
    },
    printData: [],
    printStaticData: Object,
    print: false,
    selectData_id: 0,
    dialogDeleteItem: null,
    deleteDialog: false,
    loadData: false,
    printLoading: false,
    openDialog: false,
    showDialog: false,
    printPreviewOpen: false,
    printCounter: 0,
    selectData: [],
    rowData: {},
    printHeaders: {},
    printGroupHeader: [],
    rejectMass: "",
    printFunction: Function
  }),

  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    DeleteConfirmation,
    SalaryCalculationShowDialog,
    SalaryCalculationDialog,
    GenericInput,
    SalaryCalculationPrint,
    ExportTableBtn
  },
  computed: {
    handelStatus() {
      return (row) => {
        switch (row.status) {
          case 1: return { name: "in waiting", style: "warning  py-1 px-6 rounded-pill text-caption white--text" }
          case 2: return { name: "paid off", style: "green5  py-1 px-7 rounded-pill text-caption white--text" }
          case 3: return { name: "Rejected", style: "red5  py-1 px-8 rounded-pill text-caption white--text" }
          case 4: return { name: "in partial waiting", style: "warning  py-1 px-6 rounded-pill text-caption white--text" }
        }
      }
    },
    printPreviewOpenFun() {
      return (printPreviewOpen) => this.printPreviewOpen = printPreviewOpen
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    selectData_id() {
      this.pageData.isLoading = true;
      this.$api.GET_METHOD(`salary_calculator?status=${this.selectData_id}`).then((response) => {
        if (response) {
          this.pageData.isLoading = false;
          this.pageData.rows = response.data.items;
        }
      })

    },

  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "date", key: "movement_history", type: 'text', classes: "" },
        { text: "salary calculation", key: "accounting_month", type: 'text', classes: "" },
        { text: "value", key: "total_amount", type: 'float', classes: "" },
        { text: "description", key: "notes", type: 'text', classes: "" },
        { text: "status", key: "status", type: 'slot', classes: "" },
        { text: "actions", key: "td", type: 'actions', classes: "" },
      ];

      this.selectData = [
        {
          id: 0,
          name: this.$i18n.t('view all')
        },
        {
          id: 1,
          name: this.$i18n.t('in waiting')
        },
        {
          id: 2,
          name: this.$i18n.t('paid off')
        },
        {
          id: 3,
          name: this.$i18n.t('Rejected')
        },
        {
          id: 4,
          name: this.$i18n.t('in partial waiting')
        },
      ]
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: false,
        edit: false,
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      this.loadData = true
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`salary_calculator${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data
              this.pageData.rows.forEach(row => row.printLoading = false)
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    tableDataPrint(row) {
      if (!this.printPreviewOpen) {
        return;
      }
      row.printLoading = true
      this.pageData.rows = [...this.pageData.rows]
      this.$api.GET_METHOD(`salary_calculator/${row.id}`).then(async (response) => {
        row.printLoading = false

        if (response.check) {

          this.printHeaders = await response.data.headers;
          this.pageData.printRows = await response.data.details;

          const { allowances, absence } = response.data.headers;

          this.pageData.printTableHeader = [
            { text: "employee serial number", key: "employee_id", type: 'text', classes: "", is_rowspan: true },
            { text: "employee name", key: "employee_name", type: 'text', classes: "", is_rowspan: true },
            { text: "workdays", key: "worked_days", type: 'text', classes: "", is_rowspan: true },
            { text: "the basic", key: "fixed", type: 'float', classes: "", is_rowspan: true },
            { text: "housing allowance", key: "housing_allowance", type: 'float', classes: "", is_rowspan: true },
            { text: "total additions", key: "total_additions", type: 'float', classes: "", is_rowspan: true },
            { text: 'advance', key: "advance_count", type: 'float', classes: "" },
            { text: "deduction", key: "deduction_count", type: 'float', classes: "" },
            { text: "Total deductions", key: "total_deductions", type: 'float', classes: "", is_rowspan: true },
            { text: 'days', key: 'absence_days', type: 'text', classes: "" },
            { text: 'amount', key: 'absence_value', type: 'float', classes: "" },
            { text: "the total", key: "total_amount", type: 'non_zero_float', classes: "", is_rowspan: true },
            { text: "exchange type", key: "payment_method", type: 'text', classes: "", is_rowspan: true },
          ]

          for (const row of this.pageData.printRows) {
            const rowAllowances = row.allowances || [];
            const rowDeductions = row.deductions || [];
            const rowAbsence = row.absence || {};

            for (const allowance of rowAllowances) {
              row[allowance.salary_effect_name] = allowance.amount;
            }

            let deduction_count = 0;
            let advance_count = 0;

            for (const deduction of rowDeductions) {
              if (deduction.advance_id === 0) {
                deduction_count += +deduction.amount;
              } else {
                advance_count += +deduction.amount;
              }
            }

            const absence_days = +rowAbsence.days || 0;
            const absence_value = +rowAbsence.value || 0;

            switch (row.payment_method) {
              case 1:
                row.payment_method = this.$i18n.t('Bank')
                break;
              case 2:
                row.payment_method = this.$i18n.t('cash')
                break;
              case 3:
                row.payment_method = this.$i18n.t('transfer')
                break;
            }

            row.total_additions = rowAllowances.reduce((prev, current) => prev + +current.amount, 0);
            row.total_deductions = rowDeductions.reduce((prev, current) => prev + +current.amount, 0);
            row.total_amount = (+row.fixed + +row.total_additions + +row.housing_allowance) - (+row.total_deductions + absence_value);
            row.deduction_count = deduction_count;
            row.advance_count = advance_count;
            row.absence_days = absence_days;
            row.absence_value = absence_value;
          }

          for (const item of allowances) {
            this.pageData.printTableHeader.splice(5, 0, { text: item, key: item, type: 'float', classes: "" });
          }

          console.log('this.pageData.rows', this.pageData.rows);

          this.printGroupHeader = [
            { text: "employee serial number", rowspan: 2, classes: "" },
            { text: "employee name", rowspan: 2, classes: "" },
            { text: "workdays", rowspan: 2, classes: "" },
            { text: "the basic", rowspan: 2, classes: "" },
            { text: "housing allowance", rowspan: 2, classes: "" },
            { text: "additions", colspan: allowances.length, type: 'text', classes: "", },
            { text: "total additions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
            { text: "Deductions", colspan: 2, type: 'text', classes: "", },
            { text: "Total deductions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
            { text: "absence", colspan: absence.length, type: 'text', classes: "", },
            { text: "the total", rowspan: 2, classes: "" },
            { text: "exchange type", rowspan: 2, classes: "" },
          ];
        }
      })
    },
    handelShowDialog(row) {
      this.rowData = row
      this.showDialog = true
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getRowData(row) {
      this.rowData = row
    },
    deleteMethod(status) {
      this.deleteDialog = false;
      if (status == true) {
        this.$api.POST_METHOD(`salary_calculator/${this.dialogDeleteItem.id}`, { _method: "delete" }).then((response) => {
          if (response.check) {
            this.getData();
          }
        });
      }
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`salary_calculator_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/SalaryCaculator/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>
