<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showDeleted="false" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->

      <v-row class="mt-5">
        <v-col cols="4" class="text-center" v-for="holiday in holidayData" :key="holiday.id" @click="navigationToProfile(holiday)">
          <v-card class="public_holidays pt-8 px-2 pb-6" elevation="2" >
            <span class="gray8--text text-caption font-weight-bold">{{ holiday.days }} {{ $t('Days') }}</span>
            <p class="text-h6 font-weight-bold">{{ holiday.name }}</p>

            <div class="d-flex justify-space-around">
              <span>
                <div class="red--text text-caption font-weight-bold">{{ holiday.from[0].day }}</div>
                <div class="text-h6 font-weight-bold">{{ holiday.from[0].date }}</div>
              </span>
              <span>
                <div class="red--text text-caption font-weight-bold">{{ holiday.from[1].day }}</div>
                <div class="text-h6 font-weight-bold">{{ holiday.from[1].date }}</div>
              </span>
            </div>

          </v-card>
        </v-col>

      </v-row>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";

export default {
  name: "PublicHolidaysEntity",

  data: () => ({
    pageData: {
      screen_code: "01-007",
      url: null,
      controlRoute: "/settings_hr/public_holidays-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    openForm: false,
    valid: false,
    loading: false,
    form: {},
    holidayData: [],
    deductionData_length: 0
  }),
  components: {
    EntityHeader,

  },
  computed: {
    newTotalRow() {
      return {
        id: this.$i18n.t("total number of deductions"),
        add_date: this.deductionData_length,
        amount: this.$i18n.t("total amount deductions"),
        notes: this.total_deduction_amount,
      }
    }
    
  },
  mounted() {
    // this.getData();
    this.holidayData = [
      {
        id: 1,
        name: 'عيد الفطر',
        days: 4,
        from: [
          {
            id: 1,
            day: 'السبت',
            date: '01-02-2022'
          },
          {
            id: 2,
            day: 'الربعاء',
            date: '05-02-2022'
          }
        ]
      },
      {
        id: 2,
        name: 'عيد الفطر',
        days: 4,
        from: [
          {
            id: 1,
            day: 'السبت',
            date: '01-02-2022'
          },
          {
            id: 2,
            day: 'الربعاء',
            date: '05-02-2022'
          }
        ]
      },
      {
        id: 3,
        name: 'عيد الفطر',
        days: 4,
        from: [
          {
            id: 1,
            day: 'السبت',
            date: '01-02-2022'
          },
          {
            id: 2,
            day: 'الربعاء',
            date: '05-02-2022'
          }
        ]
      },
      {
        id: 4,
        name: 'عيد الفطر',
        days: 4,
        from: [
          {
            id: 1,
            day: 'السبت',
            date: '01-02-2022'
          },
          {
            id: 2,
            day: 'الربعاء',
            date: '05-02-2022'
          }
        ]
      }
    ]
  },
  watch: {
    $route() {
      // this.getData();
    },
  },
  methods: {
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`deduction_actions${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              // rewrite deduction type
              response.data.items.data.forEach(row => {
                switch (row.value_type) {
                  case 1:
                    row.value_type = this.$i18n.t('hour')
                    break;
                  case 2:
                    row.value_type = this.$i18n.t('work days')
                    break;
                  case 3:
                    row.value_type = this.$i18n.t('value')
                    break;
                }
              });
              // End rewrite deduction type
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getEmployeeData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`get_emp_deduction/${this.employeeId}${this.pageData.queryParam}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.rows = response.data;
            this.deductionData_length = response.data.length;
            this.total_deduction_amount = response.data.reduce((prev, current) => prev + +current.amount, 0);
            response.data.forEach(e => {
              if (typeof e.amount !== Number) {
                e.amount = (e.amount).toFixed(2)
              }
            })
          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`deduction_actions/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`deduction_action_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/purchases_vendor/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    saveEmployeeData(form, valid) {
      this.form = form
      this.valid = valid
    },
    save_employee() {
      if (this.valid) {
        this.loading = true;
        this.form.employee_id = +this.employeeId
        this.$api.POST_METHOD(`deduction_actions`, this.form).then((response) => {
          this.loading = false;
          if (response.check) {
            this.openForm = false
            this.getEmployeeData()
          }
        })
      }
    },
    navigationToProfile(row) {
      
      this.$router.push(`${this.pageData.controlRoute}/${row.id}`)
    }
  },
  props: {
    employeeId: { type: String }
  }
};
</script>

<style lang="scss" scoped>
.v-card.public_holidays {
  position: relative;
  overflow: hidden;
  transition: all .4s ease-in-out;
  cursor: pointer;
}

.v-card.public_holidays:hover {
  background: rgba(128, 128, 128, 0.301);
  background: #CFD3D8;
  z-index: 500;
}

.v-card.public_holidays div {
  position: relative;
  z-index: 500;
}

.v-card.public_holidays::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url('@/assets/img/svg/celebration.svg');
  bottom: -5%;
  left: 0;
  z-index: 1;

}</style>
