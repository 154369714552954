<template>
    <v-dialog v-model="openDialog" persistent width="1000">
        <v-card class="pa-5" rounded="rounded">
            <v-form ref="form" v-model="valid">
                <v-card-title class="text-h6 px-0 font-weight-bold d-flex align-center">
                    <span v-if="clickedData?.name">{{ title }}</span>
                    <v-spacer></v-spacer>
                    <div v-if="clickedData?.name == 'interview'">
                        <v-radio-group row v-model="form.type" hide-details class="ma-0">
                            <v-radio v-for="interview in interviewWay" :key="interview.id" :label="interview.name"
                                :value="interview.id"></v-radio>
                        </v-radio-group>
                    </div>
                </v-card-title>

                <v-row>
                    <GenericInput type="date" v-if="clickedData?.name == 'acceptance'" :value="form.date"
                        @input="form.date = $event" label="Date of first day" :required="true" :isLoading="false"
                        :clearable="true" :cols="[12, 6, 6]">
                    </GenericInput>

                    <GenericInput type="file" v-if="clickedData?.name == 'acceptance'" :value="form.file"
                        @input="form.file = $event" label="Send the job offer" :base64='false' :required="true"
                        :isLoading="false" :clearable="true" :cols="[12, 6, 6]">
                    </GenericInput>


                    <!-- <v-col cols="12" md="6" v-if="clickedData?.name == 'acceptance'" class="mb-5 pa-0">
                        <div for="file_upload"  class="mb-1">{{ $t('Send the job offer') }}</div>
                        <v-btn  :loading="loading" depressed color="blue6"
                            class="blue6--text " outlined width="100%">

                            <span class="mx-1"> {{ $t('Uplaod a file...') }}</span>
                            <v-icon>mdi-cloud-upload-outline</v-icon>
                        </v-btn>
                        <input type="file" id="file_upload"  width="100%" class="px-5" />
                    </v-col> -->

                    <GenericInput type="date" v-if="clickedData?.name == 'interview'" :value="form.date"
                        @input="form.date = $event" label="date" :required="true" :isLoading="false" :clearable="true"
                        :cols="[12, 6, 6]">
                    </GenericInput>

                    <GenericInput type="time" v-if="clickedData?.name == 'interview'" :value="form.from_time"
                        @input="form.from_time = $event" label="The time is from" :required="true" :isLoading="false"
                        :clearable="true" :cols="[12, 6, 6]">
                    </GenericInput>

                    <GenericInput type="time" v-if="clickedData?.name == 'interview'" :value="form.to_time"
                        @input="form.to_time = $event" label="Hour to" :minDate="form.from_time" :required="true"
                        :isLoading="false" :clearable="true" :cols="[12, 6, 6]">
                    </GenericInput>

                    <GenericInput type="texteditor" :value="form.mail" @input="form.mail = $event" label="details"
                        :required="true" :isLoading="false" :readonly="false" :cols="[12, 12, 12]"></GenericInput>

                    <v-col cols="12" :md="clickedData?.name == 'reject' ? 4 : 6">
                        <v-btn :loading="loading" depressed @click="close" color="gray11" class="error--text " width="100%"
                            height="50">
                            {{ $t('cancel') }}
                        </v-btn>
                    </v-col>

                    <v-col cols="12" :md="clickedData?.name == 'reject' ? 4 : 6" v-if="clickedData?.name == 'reject'">
                        <v-btn :loading="loading" depressed @click="save('no_message')" color="gray5" class="gray12--text "
                            width="100%" height="50">
                            {{ $t('Rejected without sending a message') }}
                        </v-btn>
                    </v-col>

                    <v-col cols="12" :md="clickedData?.name == 'reject' ? 4 : 6">
                        <v-btn @click="save" :disabled="!valid" depressed color="blue5" class="white--text" width="100%"
                            height="50" :loading="loading">
                            {{ clickedData?.name == 'reject' ? $t('send') : $t('Select the interview and send') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "MessageDialog",
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        clickedData: { default: Object },
    },
    data: () => ({
        form: {
            date: null,
            end_date: null,
            from_time: null,
            to_time: null,
            apply_count: null,
            file: null,
            mail: null,
            type: null,
        },
        interviewWay: [],
        valid: false,
        loading: false,
    }),

    methods: {
        save(data) {
            this.loading = true
            let end_point = ''
            switch (this.clickedData?.name) {
                case 'reject':
                    end_point = 'applicant_reject'
                    break;
                case 'interview':
                    end_point = 'make_interview'
                    break;
                case 'acceptance':
                    end_point = 'applicant_accept'
            }

            data == 'no_message' && (this.form.mail = '')
            const formData = new FormData();
            Object.keys(this.form).forEach((key) => this.form[key] && (formData.append(key, this.form[key])))

            this.$api.POST_METHOD(`${end_point}/${this.clickedData.data.data.id}`, formData).then((response) => {
                this.loading = false
                if (response.check) {
                    console.log("response", response);
                    this.close()
                }
            })
        },
        close() {
            this.form = {
                date: null,
                end_date: null,
                from_time: null,
                to_time: null,
                apply_count: null,
                file: null,
                mail: null,
                type: null,
            }
            this.closeDialog()
            this.$refs.form.reset()
        }
    },
    computed: {
        title() {
            switch (this.clickedData?.name) {
                case 'reject': return this.$i18n.t('Send a rejection letter')
                case 'interview': return this.$i18n.t('Send a corresponding message')
                case 'acceptance': return this.$i18n.t('Send an acceptance letter')
                default: return this.$i18n.t('Sending a Message')
            }
        }
    },
    watch: {
        openDialog() {
            if (this.openDialog) {
                let employee_profile = this.clickedData.data.data
                let company_name = this.$store.state.userData.user.current_company_name
                this.clickedData.data.mails.map(mail => {

                    if (mail.type == 3 && this.clickedData?.name == 'reject') {
                        // reject
                        this.form.mail = mail.content
                            .replace('{career_name}', employee_profile.job_name_en)
                            .replace('{applicant}', employee_profile.full_name)
                            .replaceAll('{company_name}', company_name)
                    } else if (mail.type == 2 && this.clickedData?.name == 'acceptance') {
                        // acceptance
                        this.form.mail = mail.content
                            .replace('{career_name}', employee_profile.job_name_en)
                            .replace('{applicant}', employee_profile.full_name)
                            .replaceAll('{company_name}', company_name)
                    } else if (mail.type == 1 && this.clickedData?.name == 'interview') {
                        // interview
                        this.form.mail = mail.content
                            .replace('{career_name}', employee_profile.job_name_en)
                            .replace('{applicant}', employee_profile.full_name)
                            .replaceAll('{company_name}', company_name)
                    }
                });
            }
        },
        'form.date'(currentValue, oldValue) {
            if (this.form.date) {
                this.form.mail = this.form.mail
                    .replace(!oldValue ? '{start_date}' : oldValue, currentValue)
                    .replace(!oldValue ? '{deadline}' : this.$global.AddToDate(oldValue, 15), this.$global.AddToDate(currentValue, 15))
            }
        },
        'form.from_time'(currentValue, oldValue) {
            if (this.form.from_time) {
                if (this.clickedData?.name == 'interview') {
                    console.log(currentValue, oldValue);
                    this.form.mail = this.form.mail.replace(!oldValue ? '{interview_time}' : this.form.date + ' ' + oldValue, this.form.date + ' ' + currentValue)
                }
            }
        },
        'form.type'(currentValue, oldValue) {
            let currentCondition = currentValue == 1 ? 'in the Office' : 'Online'
            let oldCondition = oldValue == 1 ? 'in the Office' : 'Online'
            if (this.form.type) {
                this.form.mail = this.form.mail.replace(!oldValue ? '{interview_type}' : oldCondition, currentCondition)
            }
        }
    },
    mounted() {
        this.interviewWay = [
            {
                id: 1,
                name: this.$i18n.t('in the office')
            },
            {
                id: 2,
                name: this.$i18n.t('Online')
            },
        ]
    }
}


</script>

<style lang="scss" scoped></style>
