<template>
  <v-row>
    <v-col cols="2">
      <v-card class="mx-auto rounded-lg">
        <v-navigation-drawer permanent>
          <v-list dense >
            <v-list-item-group mandatory v-model="path"  color="primary">
              <!-- :exact-active-class="false" :exact-path="false" :exact="false" -->
              <v-list-item v-for="(item, i) in items" :key="i" :to="item.link" >
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize">{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </v-col>
    <v-col cols="10 flex-grow-1">
      <v-card class="mx-auto rounded-lg">
        <router-view></router-view>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "SettingsHR",
  components: {},
  data: () => ({
    path: null,
    items: [
      { text: 'financial year', link: '/settings_hr/financial_year' },
      { text: 'accounts', link: '/settings_hr/accounts' },
      { text: 'Personal Account', link: '/settings_hr/personal_account' },
      { text: 'notifications', link: '/settings_hr/notifications' },
      { text: 'the requests', link: '/settings_hr/requests_settings' },
      { text: 'public holidays', link: '/settings_hr/public_holidays' },
    ],
  }),
};
</script>

<style lang="scss" scoped>
a.v-item--active.v-list-item--active.v-list-item.v-list-item--link.theme--light {
  position: relative;
}

.v-application--is-rtl {
  a.v-item--active.v-list-item--active.v-list-item.v-list-item--link.theme--light::after {
    content: "";
    position: absolute;
    right: -2px;
    background: var(--primary);
    width: 8px;
    border-radius: 15px;
  }
}
.v-application--is-ltr {
  a.v-item--active.v-list-item--active.v-list-item.v-list-item--link.theme--light::after {
    content: "";
    position: absolute;
    left: -2px;
    background: var(--primary);
    width: 8px;
    border-radius: 15px;
  }
}
</style>
